import styled from 'styled-components'

export const RoundBtn = styled.button<{ $bgColor?: string }>`
  flex: 1;
  border: none;
  background: ${(props) => props.$bgColor || '#22b4e2'};
  color: white;
  text-align: center;
  text-decoration: none;
  height: 48px;
  border-radius: 10px;
  font-family: 'Pretendard';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  &:hover {
    cursor: pointer;
  }
`

export const PopupText = styled.text`
  width: 100%;
  margin: 0;
  resize: none;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 12px;
  font-family: 'Pretendard ';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  border-radius: 5px;
  background: #fdfdfd;
  border: 1px solid transparent;
  color: #333333;
  text-align: center;
  margin-bottom: 36px;
  white-space: pre-wrap;
`
