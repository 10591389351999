import React, { useState } from 'react'
import { ProgressBar } from './progressBar'
import { ArrowDown, ArrowUp } from 'lucide-react'
import './DeliveryStatusCard.css'

export const DeliveryStatusCard = ({ item }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const getProgressStep = (status) => {
    const statusMap = {
      '카벨 확인': 0,
      '파트너사 이동중': 1,
      입고: 2,
      출고: 3,
      완료: 4
    }
    return statusMap[status] || 0
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')

    return `${year}년 ${month}월 ${day}일 ${hours}시`
  }

  return (
    <div className={`delivery-card ${isExpanded ? 'expanded' : ''}`}>
      <div className='card-header'>
        <h2>{item.type} 서비스</h2>
        <span>{formatDate(item.requestDate)}</span>
      </div>
      <div className='card-content'>
        <div className='info-row'>
          <span className='label'>차량 정보</span>
          <span>{item.carInfo}</span>
        </div>
        <div className='info-row'>
          <span className='label'>상태</span>
          <span>{item.status}</span>
        </div>
        <ProgressBar currentStep={getProgressStep(item.status)} />
        <div className={`additional-info ${isExpanded ? 'show' : ''}`}>
          <div className='info-row'>
            <span className='label'>정비소 정보</span>
            <span>{item.partnerCompanyName}</span>
          </div>
          <div className='info-row'>
            <span className='label'>요청 사항</span>
            <span>{item.requestContent}</span>
          </div>
          <div className='info-row'>
            <span className='label'>입차인 정보</span>
            <div>
              <div>{item.renterName}</div>
              <div>{item.renterPhone}</div>
            </div>
          </div>
          <div className='info-row'>
            <span className='label'>픽업 위치</span>
            <span>{item.carLocation}</span>
          </div>
        </div>
      </div>
      <div className='card-footer'>
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className='toggle-button'
        >
          {isExpanded ? '닫기' : '더보기'}
          {isExpanded ? (
            <ArrowUp className='arrow-up' />
          ) : (
            <ArrowDown className='arrow-up' />
          )}
        </button>
      </div>
    </div>
  )
}
