import { PopupText, RoundBtn } from '../CommonUI'
import React from 'react'

export const YesNoPopUp = ({
  errMsg,
  clickEvent
}: {
  errMsg: string
  clickEvent: (isShowed: boolean) => void
}) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          position: 'fixed',
          top: '0px',
          left: '0px',
          width: '100%',
          height: '100%',
          background: 'black',
          opacity: '0.3'
        }}
      />
      <div
        style={{
          width: '480px',
          background: 'white',
          borderRadius: '10px',
          zIndex: '100',
          marginBottom: '300px'
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <PopupText>{errMsg}</PopupText>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <RoundBtn style={{}} onClick={() => clickEvent(true)}>
              확인
            </RoundBtn>
            <RoundBtn
              style={{ background: '#333333', marginLeft: '20px' }}
              onClick={() => clickEvent(false)}
            >
              취소
            </RoundBtn>
          </div>
        </div>
      </div>
    </div>
  )
}

export const ConfirmPopUp = ({
  errMsg,
  clickEvent
}: {
  errMsg: string
  clickEvent: () => void
}) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          position: 'fixed',
          top: '0px',
          left: '0px',
          width: '100%',
          height: '100%',
          background: 'black',
          opacity: '0.3'
        }}
      />
      <div
        style={{
          width: '480px',
          background: 'white',
          borderRadius: '10px',
          zIndex: '100',
          marginBottom: '300px'
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <PopupText>{errMsg}</PopupText>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <RoundBtn style={{}} onClick={() => clickEvent()}>
              확인
            </RoundBtn>
          </div>
        </div>
      </div>
    </div>
  )
}

export const ConfirmPopUpM = ({
  errMsg,
  clickEvent
}: {
  errMsg: string
  clickEvent: () => void
}) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          position: 'fixed',
          top: '0px',
          left: '0px',
          width: '100%',
          height: '100%',
          background: 'black',
          opacity: '0.3'
        }}
      />
      <div
        style={{
          width: '90%',
          background: 'white',
          borderRadius: '10px',
          zIndex: '100'
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <PopupText>{errMsg}</PopupText>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <RoundBtn style={{}} onClick={() => clickEvent()}>
              확인
            </RoundBtn>
          </div>
        </div>
      </div>
    </div>
  )
}

export const YesNoPopUpM = ({
  errMsg,
  clickEvent
}: {
  errMsg: string
  clickEvent: (isShowed: boolean) => void
}) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          position: 'fixed',
          top: '0px',
          left: '0px',
          width: '100%',
          height: '100%',
          background: 'black',
          opacity: '0.3'
        }}
      />
      <div
        style={{
          width: '90%',
          background: 'white',
          borderRadius: '10px',
          zIndex: '100'
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <PopupText>{errMsg}</PopupText>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <RoundBtn style={{}} onClick={() => clickEvent(true)}>
              확인
            </RoundBtn>
            <RoundBtn
              style={{ background: '#333333', marginLeft: '10px' }}
              onClick={() => clickEvent(false)}
            >
              취소
            </RoundBtn>
          </div>
        </div>
      </div>
    </div>
  )
}

export const buyMsg =
  '이용권 구매를 요청하시겠습니까?\n\n서비스 이용요금 (VAT포함)\n반납대행: 38,500원\n정비/사고/검사대행: 8,800원'
