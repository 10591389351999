import { configureStore } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import { rootReducer, RootReducer } from './reducers/RootReducer'
import storageSession from 'redux-persist/lib/storage/session'

const persistConfig = {
  key: 'auth',
  //local storage에 저장
  storage: storageSession
}

const persistedReducer = persistReducer<RootReducer>(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  // devTools: true

  // 다음이 middleware 추가 코드이다.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  // 기본 값이 true지만 배포할때 코드를 숨기기 위해서 false로 변환하기 쉽게 설정에 넣어놨다.
  devTools: true
})

export default store
