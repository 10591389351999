// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar-container {
  position: relative;
  padding-top: 16px;
  margin-bottom: 40px;
}

.progress-line {
  height: 2px;
  background-color: #d1d5db;
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
}

.progress-bullet {
  width: 12px;
  height: 12px;
  background-color: #3b82f6;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out;
}

.steps-container {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.step-text {
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  width: 64px;
  margin-left: -32px;
}

.first-step {
  margin-left: 0;
}

.last-step {
  margin-right: 0;
}

/* 반응형 디자인을 위한 미디어 쿼리 */
@media (max-width: 640px) {
  .step-text {
    font-size: 8px;
    width: 48px;
    margin-left: -24px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ProgressBar.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,QAAQ;EACR,OAAO;EACP,QAAQ;AACV;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,QAAQ;EACR,2BAA2B;EAC3B,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA,uBAAuB;AACvB;EACE;IACE,cAAc;IACd,WAAW;IACX,kBAAkB;EACpB;AACF","sourcesContent":[".progress-bar-container {\n  position: relative;\n  padding-top: 16px;\n  margin-bottom: 40px;\n}\n\n.progress-line {\n  height: 2px;\n  background-color: #d1d5db;\n  position: absolute;\n  top: 8px;\n  left: 0;\n  right: 0;\n}\n\n.progress-bullet {\n  width: 12px;\n  height: 12px;\n  background-color: #3b82f6;\n  border-radius: 50%;\n  position: absolute;\n  top: 2px;\n  transform: translateX(-50%);\n  transition: all 0.3s ease-in-out;\n}\n\n.steps-container {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 12px;\n}\n\n.step-text {\n  font-size: 10px;\n  font-weight: 600;\n  text-align: center;\n  width: 64px;\n  margin-left: -32px;\n}\n\n.first-step {\n  margin-left: 0;\n}\n\n.last-step {\n  margin-right: 0;\n}\n\n/* 반응형 디자인을 위한 미디어 쿼리 */\n@media (max-width: 640px) {\n  .step-text {\n    font-size: 8px;\n    width: 48px;\n    margin-left: -24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
