//import HeaderM from './HeaderM'
import styled from 'styled-components'
import React from 'react'
import HeaderM from './HeaderM'

const Main = styled.main`
  width: 100%;
`
const LayoutM = (props: { children: React.ReactNode; myPage: boolean }) => {
  return (
    <>
      <HeaderM MyPage={props.myPage} />
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 54px)',
          background: 'transparent',
          position: 'absolute',
          marginTop: '0px',
          marginBottom: '0px',
          top: '54px',
          right: '0px',
          margin: 'auto',
          display: 'flex'
        }}
      >
        <Main>{props.children}</Main>
      </div>
    </>
  )
}
export default LayoutM
