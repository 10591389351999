import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { IS_VALID_TOKEN, SERVER_URL } from '../components/Common'
import { RootReducer } from '../redux/reducers/RootReducer'
import styled from 'styled-components'
import { setToken } from '../redux/reducers/AuthReducer'
import { ConfirmPopUp } from '../components/popup/PopUp'
const LoginBtn = styled.button`
  margin-top: 30px;
  border: none;
  background: #22b4e2;
  color: white;
  text-align: center;
  text-decoration: none;
  width: 420px;
  height: 60px;
  border-radius: 70px;
  font-family: 'Pretendard';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  &:hover {
    cursor: pointer;
  }
`

const InputBG = styled.div<{ $top?: string }>`
  width: 418px;
  height: 54px;
  display: flex;
  align-items: center;
  margin-top: ${(props) => props.$top || '200px'};
`
const InputBox = styled.input`
  font-family: 'Pretendard';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  width: 418px;
  height: 54px;
  margin-top: 0px;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  background: #fdfdfd;
  padding-left: 62px;
  &:focus {
    outline: none !important;
  }
`
const InputComp = ({
  top,
  type,
  hint,
  srcPath,
  datas,
  saveEvent
}: {
  top: string
  type: string
  hint: string
  srcPath: string
  datas: string
  saveEvent: React.ChangeEventHandler<HTMLInputElement>
}) => {
  return (
    <InputBG $top={top}>
      <InputBox
        type={type}
        placeholder={hint}
        style={{ position: 'relative' }}
        value={datas}
        onChange={saveEvent}
      />
      <img
        style={{
          marginTop: '0px',
          position: 'absolute',
          marginLeft: '30px'
        }}
        width={'24px'}
        height={'24px'}
        src={srcPath}
        alt='id icon'
      />
    </InputBG>
  )
}

const Login = () => {
  const [show, setShow] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [idValue, setId] = useState('')
  const [pwValue, setPw] = useState('')

  const token = useSelector((state: RootReducer) => state.Auth.token)
  useEffect(() => {
    const infos = JSON.parse(token)
    if (!IS_VALID_TOKEN(infos)) navigate('/login')
    else navigate('/member')
  }, [])
  const confirmClicked = () => {
    setShow(false)
  }

  const saveUserId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setId(event.target.value)
  }

  const saveUserPw = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPw(event.target.value)
  }

  const movePage = () => {
    const params = {
      // 필요한 query params를 {} 형태에 담아준다.
      id: idValue,
      password: pwValue
    }
    fetch(`${SERVER_URL()}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify(params)
    })
      .then((res) => res.json()) // 리턴값이 있으면 리턴값에 맞는 req 지정
      .then((res) => {
        if (res.code == 0) {
          dispatch(setToken(JSON.stringify(res)))
          navigate('/member')
        } else {
          setId('')
          setPw('')
          setErrMsg('로그인에 실패하였습니다!')
          setShow(true)
        }
      })
  }

  return (
    <>
      <Layout minHeight={680}>
        <div
          style={{
            position: 'absolute',
            flexDirection: 'column',
            width: '1096px',
            minWidth: '438px',
            //maxHeight: 'calc(100vh - 260px - 140px - 103px + 80px)',
            maxHeight: '680px',
            background: 'white',
            alignItems: 'center',
            borderRadius: '20px',
            top: '138px',
            boxShadow: '0px 2px 18px 0px rgba(0,0,0,0.3)',
            left: '50%',
            transform: 'translate(-50%)'
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              background: 'white',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: '20px'
            }}
          >
            <img
              style={{ marginTop: '80px' }}
              width={'98px'}
              height={'28px'}
              src={require('../assets/img/logo.png')}
              alt='BigCo Inc. logo'
            />

            <div
              style={{
                marginTop: '6px',
                marginBottom: '30px',
                color: '#0862A9',
                fontFamily: 'Pretendard',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: 800,
                lineHeight: '32px'
              }}
            >
              카벨 고객사 로그인
            </div>
            <InputComp
              top='53px'
              type='text'
              hint='아이디'
              srcPath={
                idValue.length == 0
                  ? require('../assets/img/id.png')
                  : require('../assets/img/id-focus.png')
              }
              datas={idValue}
              saveEvent={saveUserId}
            ></InputComp>
            <InputComp
              top='12px'
              type='password'
              hint='패스워드'
              srcPath={
                pwValue.length == 0
                  ? require('../assets/img/pw.png')
                  : require('../assets/img/pw-focus.png')
              }
              datas={pwValue}
              saveEvent={saveUserPw}
            />

            <LoginBtn onClick={movePage} style={{ marginBottom: '30px' }}>
              로그인
            </LoginBtn>
          </div>
        </div>
      </Layout>

      <>
        {' '}
        {show && <ConfirmPopUp errMsg={errMsg} clickEvent={confirmClicked} />}
      </>
    </>
  )
}

export default Login
