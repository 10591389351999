// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Style The Dropdown Button */
.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer; }

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block; }

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1; }

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block; }

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1; }

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block; }

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41; }

::placeholder {
  color: #949494; }
`, "",{"version":3,"sources":["webpack://./src/pages/ReturnRegist.scss"],"names":[],"mappings":"AAAC,8BAAA;AACD;EACE,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,eAAe;EACf,YAAY;EACZ,eAAe,EAAA;;AAGjB,kEAAA;AACA;EACE,kBAAkB;EAClB,qBAAqB,EAAA;;AAGvB,yCAAA;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,gBAAgB;EAChB,+CAA4C;EAC5C,UAAU,EAAA;;AAGZ,8BAAA;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,qBAAqB;EACrB,cAAc,EAAA;;AAGhB,4CAAA;AACA;EAA2B,yBAAyB,EAAA;;AAEpD,oCAAA;AACA;EACE,cAAc,EAAA;;AAGhB,0FAAA;AACA;EACE,yBAAyB,EAAA;;AAG1B;EACE,cAAc,EAAA","sourcesContent":[" /* Style The Dropdown Button */\n.dropbtn {\n  background-color: #4CAF50;\n  color: white;\n  padding: 16px;\n  font-size: 16px;\n  border: none;\n  cursor: pointer;\n}\n\n/* The container <div> - needed to position the dropdown content */\n.dropdown {\n  position: relative;\n  display: inline-block;\n}\n\n/* Dropdown Content (Hidden by Default) */\n.dropdown-content {\n  display: none;\n  position: absolute;\n  background-color: #f9f9f9;\n  min-width: 160px;\n  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);\n  z-index: 1;\n}\n\n/* Links inside the dropdown */\n.dropdown-content a {\n  color: black;\n  padding: 12px 16px;\n  text-decoration: none;\n  display: block;\n}\n\n/* Change color of dropdown links on hover */\n.dropdown-content a:hover {background-color: #f1f1f1}\n\n/* Show the dropdown menu on hover */\n.dropdown:hover .dropdown-content {\n  display: block;\n}\n\n/* Change the background color of the dropdown button when the dropdown content is shown */\n.dropdown:hover .dropbtn {\n  background-color: #3e8e41;\n}\n\n ::placeholder {\n   color: #949494;\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
