import React, { useEffect, useState } from 'react'
import LayoutM from '../../components/LayoutM'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { setToken } from '../../redux/reducers/AuthReducer'
import { useDispatch, useSelector } from 'react-redux'
import { IS_VALID_TOKEN, SERVER_URL } from '../../components/Common'
import { RootReducer } from '../../redux/reducers/RootReducer'
import { ConfirmPopUpM } from '../../components/popup/PopUp'

const LoginBtn = styled.button`
  margin-top: 0px;
  border: none;
  background: #22b4e2;
  color: white;
  text-align: center;
  text-decoration: none;
  width: 100%;
  height: 48px;
  border-radius: 70px;
  font-family: 'Pretendard';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  &:hover {
    cursor: pointer;
  }
`

const InputBG = styled.div<{ $top?: string }>`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  margin-top: ${(props) => props.$top || '200px'};
`
const InputBox = styled.input`
  font-family: 'Pretendard';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  width: 100%;
  height: 48px;
  margin-top: 0px;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  background: #fdfdfd;
  padding-left: 62px;
  &:focus {
    outline: none !important;
  }
`
const InputComp = ({
  top,
  type,
  hint,
  srcPath,
  datas,
  saveEvent
}: {
  top: string
  type: string
  hint: string
  srcPath: string
  datas: string
  saveEvent: React.ChangeEventHandler<HTMLInputElement>
}) => {
  return (
    <InputBG $top={top}>
      <InputBox
        type={type}
        placeholder={hint}
        style={{ position: 'relative' }}
        value={datas}
        onChange={saveEvent}
      />
      <img
        style={{
          marginTop: '0px',
          position: 'absolute',
          marginLeft: '30px'
        }}
        width={'24px'}
        height={'24px'}
        src={srcPath}
        alt='id icon'
      />
    </InputBG>
  )
}

const LoginM = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [idValue, setId] = useState('')
  const [pwValue, setPw] = useState('')

  const [show, setShow] = useState(false)
  const [errMsg, setErrMsg] = useState('')

  const token = useSelector((state: RootReducer) => state.Auth.token)
  useEffect(() => {
    const infos = JSON.parse(token)
    if (!IS_VALID_TOKEN(infos)) navigate('/login')
    else navigate('/member')
  }, [])

  const confirmClicked = () => {
    setShow(false)
  }

  const saveUserId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setId(event.target.value)
  }

  const saveUserPw = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPw(event.target.value)
  }

  function movePage() {
    const params = {
      // 필요한 query params를 {} 형태에 담아준다.
      id: idValue,
      password: pwValue
    }

    fetch(`${SERVER_URL()}/login`, {
      method: 'POST', //메소드 지정
      headers: {
        //'Access-Control-Allow-Origin': '*',
        //'Access-Control-Allow-Headers':
        //  'Origin, X-Requested-With, Content-Type, Accept',
        //데이터 타입 지정
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify(params)
    })
      .then((res) => res.json()) // 리턴값이 있으면 리턴값에 맞는 req 지정
      .then((res) => {
        if (res.code == 0) {
          dispatch(setToken(JSON.stringify(res)))
          navigate('/member')
        } else {
          setId('')
          setPw('')
          setErrMsg('로그인에 실패하였습니다!')
          setShow(true)
        }
      })
  }

  return (
    <>
      <LayoutM myPage={false}>
        <div
          style={{
            width: '100%',
            height: '100%',
            background: '#0862A9',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              width: '100%',
              background: 'white',
              borderRadius: '10px',
              boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
              marginLeft: '20px',
              marginRight: '20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <div
              style={{
                width: 'calc(100% - 40px)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <img
                style={{ marginTop: '60px' }}
                width={'98px'}
                height={'28px'}
                src={require('../../assets/img/logo.png')}
                alt='BigCo Inc. logo'
              />

              <div
                style={{
                  marginTop: '4px',
                  marginBottom: '0px',
                  color: '#0862A9',
                  fontFamily: 'Pretendard',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 800,
                  lineHeight: '32px'
                }}
              >
                카벨 고객사 로그인
              </div>

              <InputComp
                top='53px'
                type='text'
                hint='아이디'
                srcPath={
                  idValue.length == 0
                    ? require('../../assets/img/id.png')
                    : require('../../assets/img/id-focus.png')
                }
                datas={idValue}
                saveEvent={saveUserId}
              ></InputComp>

              <InputComp
                top='12px'
                type='password'
                hint='패스워드'
                srcPath={
                  pwValue.length == 0
                    ? require('../../assets/img/pw.png')
                    : require('../../assets/img/pw-focus.png')
                }
                datas={pwValue}
                saveEvent={saveUserPw}
              />

              <LoginBtn
                onClick={movePage}
                style={{
                  marginTop: '24px',
                  width: '100%',
                  marginBottom: '40px'
                }}
              >
                로그인
              </LoginBtn>
            </div>
          </div>
        </div>
      </LayoutM>
      <>
        {' '}
        {show && <ConfirmPopUpM errMsg={errMsg} clickEvent={confirmClicked} />}
      </>
    </>
  )
}

export default LoginM
