import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const FilterItem = styled.div`
  margin-right: 20px;
`

const FilterLabel = styled.label`
  margin-right: 5px;
  font-family: 'Pretendard';
  font-size: 16px;
  font-weight: 400;
  color: #191919;
`

interface FilterValues {
    type: string
    createDate: string
    carNumber: string
    dispatchType: string
}

interface FilterBarProps {
    onFilterChange: (filters: FilterValues) => void
}

const FilterBar: React.FC<FilterBarProps> = ({ onFilterChange }) => {
    const [type, setType] = useState('')
    const [createDate, setCreateDate] = useState('')
    const [carNumber, setCarNumber] = useState('')
    const [dispatchType, setDispatchType] = useState('')

    useEffect(() => {
        onFilterChange({ type, createDate, carNumber, dispatchType })
    }, [type, createDate, carNumber, dispatchType, onFilterChange])

    return (
        <FilterContainer>
            <FilterItem>
                <FilterLabel>서비스 유형:</FilterLabel>
                <select value={type} onChange={(e) => setType(e.target.value)}>
                    <option value=''>전체</option>
                    <option value='경정비'>경정비</option>
                    <option value='사고수리'>사고수리</option>
                    <option value='자동차 검사'>자동차 검사</option>
                    <option value='렌터카 반납'>렌터카 반납</option>
                </select>
            </FilterItem>
            <FilterItem>
                <FilterLabel>배차 형태:</FilterLabel>
                <select value={dispatchType} onChange={(e) => setDispatchType(e.target.value)}>
                    <option value=''>전체</option>
                    <option value='일반 단기'>일반 단기</option>
                    <option value='일반 장기'>일반 장기</option>
                    <option value='기타'>기타</option>
                </select>
            </FilterItem>
            <FilterItem>
                <FilterLabel>생성일:</FilterLabel>
                <input
                    type='date'
                    value={createDate}
                    onChange={(e) => setCreateDate(e.target.value)}
                />
            </FilterItem>
            <FilterItem>
                <FilterLabel>차량 번호:</FilterLabel>
                <input
                    type='text'
                    placeholder='차량 번호 입력'
                    value={carNumber}
                    onChange={(e) => setCarNumber(e.target.value)}
                />
            </FilterItem>
        </FilterContainer>
    )
}

export default FilterBar
