import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import moment from 'moment'

const CalendarContainer = styled.div`
  position: relative;
`

const DropdownButton = styled.button`
  width: 160px;
  height: 58px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 0px 12px;
  color: var(--festie-gray-800, #3a3a3a);
  font-size: 16px;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: start;
  appearance: none;
  background-color: white;
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 12px;
`

const CalendarWrapper = styled.div<{
  $isOpen?: boolean
}>`
  z-index: 11;
  position: absolute;
  top: 100%;
  left: 0;
  display: ${(props) => (props.$isOpen ? 'block' : 'none')};
`

const CustomCalendar = ({ onChange, value, serviceType }) => {
  const [displayDate, setDisplayDate] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (serviceType === '경정비' || serviceType === '사고수리') {
      setDisplayDate(moment().format('YYYY년 MM월 DD일'))
    } else {
      setDisplayDate('신청 일시')
    }
  }, [serviceType])

  const handleToggleCalendar = () => {
    setIsOpen(!isOpen)
  }

  const handleDateChange = (selectedDate) => {
    onChange(selectedDate)
    setIsOpen(false)
    setDisplayDate(moment(selectedDate).format('YYYY년 MM월 DD일'))
  }

  return (
    <CalendarContainer>
      <DropdownButton onClick={handleToggleCalendar}>
        {displayDate}
      </DropdownButton>
      <CalendarWrapper $isOpen={isOpen}>
        <Calendar onChange={handleDateChange} value={value}></Calendar>
      </CalendarWrapper>
    </CalendarContainer>
  )
}

export default CustomCalendar
