import Footer from './Footer'
import Header from './Header'
import styled from 'styled-components'
import React, { useEffect, useState } from 'react'

const Main = styled.main`
  width: 100%;
`

const Layout = ({
  children,
  minHeight
}: {
  children: React.ReactNode
  minHeight: number
}) => {
  const [, setHeight] = useState(window.innerHeight)
  const [scrolled, setScrolled] = useState(false)
  const handleResize = () => {
    if (window.innerHeight < minHeight + 260) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      // cleanup
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <div>
        <Header isLong={false} />

        <div
          style={{
            width: '100%',
            height: scrolled ? 'calc(100vh)' : 'calc(100vh - 200px)',
            background: 'white',
            marginTop: '0px',
            marginBottom: '0px',
            top: '60px',
            right: '0px',
            margin: 'auto'
          }}
        >
          <div
            style={{
              width: '100%',
              //height: 'calc(100vh - 200px)'
              height: '100%'
            }}
          >
            <div
              style={{
                float: 'left',
                width: '100%',
                height: '30%',
                background: '#0862A9'
              }}
            />

            <div
              style={{
                float: 'left',
                width: '100%',
                height: '70%',
                background: '#FFFFFF'
              }}
            />
          </div>
          <Main>{children}</Main>
        </div>
        <Footer />
      </div>
    </>
  )
}
export default Layout
