import './ProgressBar.css'

const steps = ['카벨 확인', '파트너사 이동중', '입고', '출고', '완료']

export const ProgressBar = ({ currentStep }) => {
  const bulletPosition = `${((currentStep + 1) / steps.length) * 100 - 11}%`

  return (
    <div className='progress-bar-container'>
      <div className='progress-line'></div>
      <div className='progress-bullet' style={{ left: bulletPosition }}></div>
      <div className='steps-container'>
        {steps.map((step, index) => (
          <div
            key={index}
            className={`step-text ${index === 0 ? 'first-step' : ''} ${index === steps.length - 1 ? 'last-step' : ''}`}
          >
            {step}
          </div>
        ))}
      </div>
    </div>
  )
}
