import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { RootReducer } from '../redux/reducers/RootReducer'
import { useNavigate } from 'react-router-dom'
import {
  IS_VALID_TOKEN,
  sendBuySlackMessage,
  SERVER_URL
} from '../components/Common'
import { buyMsg, YesNoPopUp } from '../components/popup/PopUp'

const Button = styled.button<{
  $width?: string
  $height?: string
  $color?: string
  $flex?: number
}>`
  width: ${(props) => (props.$width ? props.$width : '0px')};
  height: ${(props) => (props.$height ? props.$height : '0px')};
  font-family: 'Pretendard';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  background: ${(props) => (props.$color ? props.$color : 'white')};
  color: white;
  border: none;
  border-radius: 10px;
  flex: ${(props) => (props.$flex ? props.$flex : 'none')};
`

const Text = styled.p<{ $size?: string; $weight?: number }>`
  font-family: 'Pretendard';
  font-size: ${(props) => (props.$size ? props.$size : '20px')};
  font-style: normal;
  font-weight: ${(props) => (props.$weight ? props.$weight : 400)};
  line-height: 26px;
  color: #191919;
`
const Member = () => {
  const navigate = useNavigate()
  const [validToken, setValidToken] = useState(false)

  const [show, setShow] = useState(false)
  const [errMsg] = useState(buyMsg)

  const [ticketCount, setTicketCount] = useState(0)

  const token = useSelector((state: RootReducer) => state.Auth.token)
  const infos = JSON.parse(token)
  useEffect(() => {
    const infos = JSON.parse(token)
    if (!IS_VALID_TOKEN(infos)) navigate('/login')
    else {
      setValidToken(true)
      const params = {
        // 필요한 query params를 {} 형태에 담아준다.
        id: infos.data.idx
      }

      fetch(`${SERVER_URL()}/user/info`, {
        method: 'POST', //메소드 지정
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${infos.data.auth_token}`
        },
        body: JSON.stringify(params)
      })
        .then((res) => res.json()) // 리턴값이 있으면 리턴값에 맞는 req 지정
        .then((res) => {
          if (res.code == 0) {
            setTicketCount(res.data.ticket_count)
          }
        })
    }
  }, [])

  const clickEvent = (value: boolean) => {
    setShow(false)
    if (value) sendBuySlackMessage(infos.data.company_name, infos.data.user_tel)
  }

  return (
    <>
      <Layout minHeight={680}>
        <div
          style={{
            overflowY: 'auto',
            position: 'absolute',
            flexDirection: 'column',
            width: '1096px',
            minWidth: '438px',
            height: '440px',
            //height: 'calc(100vh - 260px - 140px - 103px + 80px)',
            maxHeight: '480px',
            background: 'white',
            alignItems: 'center',
            borderRadius: '25px',
            top: '138px',
            boxShadow: '0px 2px 18px 0px rgba(0,0,0,0.3)',
            left: '50%',
            transform: 'translate(-50%)'
          }}
        >
          <div
            style={{
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              background: 'transparent',
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <div
              style={{
                marginTop: '80px',
                marginBottom: '40px',
                width: '90%',
                flexDirection: 'row',
                justifyContent: 'center',
                display: 'flex'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <img
                  width={'48px'}
                  height={'48px'}
                  src={require('../assets/img/car-icon.png')}
                  alt='BigCo Inc. logo'
                />
              </div>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  flexDirection: 'column',
                  display: 'flex',
                  marginLeft: '15px'
                }}
              >
                <Text $size={'20px'} $weight={500} style={{ margin: '0' }}>
                  {validToken ? infos.data.user_name : ''}
                </Text>
                <Text $size={'16px'} $weight={400} style={{ margin: '0' }}>
                  {validToken ? infos.data.company_name : ''} ・{' '}
                  {validToken ? infos.data.user_email : ''}
                </Text>
              </div>
            </div>

            <div
              style={{
                width: '95%',
                height: '1px',
                background: '#E5E5E5'
              }}
            ></div>

            <div
              style={{
                width: '90%',
                height: '60%'
              }}
            >
              <div
                style={{
                  width: '100%',
                  marginTop: '39px',
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1
                }}
              >
                <Text $size={'20px'} $weight={500} style={{ flex: 0.5 }}>
                  잔여 이용권
                </Text>
                <Text $size={'20px'} $weight={400} style={{ flex: 1 }}>
                  {validToken ? ticketCount : '0'} 개
                </Text>
                <Button
                  $width={'160px'}
                  $height={'60px'}
                  $color={'#22B4E2'}
                  onClick={() => setShow(true)}
                >
                  이용권 구매
                </Button>
              </div>

              <div
                style={{
                  marginTop: '40px',
                  width: '100%',
                  paddingBottom: '40px',
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1
                }}
              >
                <Button
                  $width={'430px'}
                  $height={'60px'}
                  $color={'#0862A9'}
                  $flex={1}
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigate('/regist')}
                >
                  신청하기
                </Button>
                <div style={{ width: '30px' }}></div>
                <Button
                  $width={'430px'}
                  $height={'60px'}
                  $color={'#0862A9'}
                  $flex={1}
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigate('/list')}
                >
                  신청 이력
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <> {show && <YesNoPopUp errMsg={errMsg} clickEvent={clickEvent} />}</>
    </>
  )
}

export default Member
