import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { getStatusType, SERVER_URL } from './Common'
import Background from '../../src/assets/img/line.png'
import { NavigateFunction } from 'react-router-dom'
import { initToken } from '../redux/reducers/AuthReducer'
import { useDispatch } from 'react-redux'

const ROW_ONE = styled.div<{ $show?: boolean; $type?: string }>`
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 20px;
  border: 1px solid ${(props) => (props.$type === '0' ? '#22B4E2' : '#0862A9')};
`

const Text = styled.p<{ $size?: string; $weight?: number; $color?: string }>`
  font-family: 'Pretendard';
  font-size: ${(props) => (props.$size ? props.$size : '20px')};
  font-style: normal;
  font-weight: ${(props) => (props.$weight ? props.$weight : 400)};
  line-height: 26px;
  color: ${(props) => (props.$color ? props.$color : '#191919')};
`

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: white;
`
const StepStatus = ({ status }: { status: string }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          flex: 1,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: `url(${Background})`
        }}
      >
        <Dot
          style={{
            display: status == '0' ? 'block' : 'none',
            boxShadow: '1px 2px 9px #FFFFFF'
          }}
        />
      </div>

      <div
        style={{
          flex: 1.3,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: `url(${Background})`
        }}
      >
        <Dot style={{ display: status == '1' ? 'block' : 'none' }} />
      </div>

      <div
        style={{
          flex: 1,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: `url(${Background})`
        }}
      >
        <Dot style={{ display: status == '2' ? 'block' : 'none' }} />
      </div>

      <div
        style={{
          flex: 1,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: `url(${Background})`
        }}
      >
        <Dot style={{ display: status == '3' ? 'block' : 'none' }} />
      </div>

      <div
        style={{
          flex: 1,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: `url(${Background})`
        }}
      >
        <Dot style={{ display: status == '4' ? 'block' : 'none' }} />
      </div>

      <div
        style={{
          flex: 1,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: `url(${Background})`
        }}
      >
        <Dot style={{ display: status == '5' ? 'block' : 'none' }} />
      </div>
    </div>
  )
}
const StepText = ({ msg, flex }: { msg: string; flex: string }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: flex
      }}
    >
      <Text
        $size={'12px'}
        $weight={400}
        $color={'white'}
        style={{
          margin: '0',
          marginTop: '5px',
          marginBottom: '10px',
          textAlign: 'center',
          whiteSpace: 'pre-line',
          lineHeight: '18px'
        }}
      >
        {msg}
      </Text>
    </div>
  )
}

const OneRowText = ({
  title,
  contents,
  marginTop,
  isLeft
}: {
  title: string
  contents: string
  marginTop: string
  isLeft: boolean
}) => {
  return (
    <div
      style={{
        width: '100%',
        marginTop: marginTop,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <Text
        $size={'14px'}
        $weight={600}
        $color={'#363636'}
        style={{ margin: '0', marginLeft: '20px', flex: '2' }}
      >
        {title}
      </Text>
      <Text
        $size={'14px'}
        $weight={400}
        $color={'#363636'}
        style={{
          margin: '0',
          marginRight: '20px',
          flex: '5',
          textAlign: isLeft ? 'left' : 'right'
        }}
      >
        {contents}
      </Text>
    </div>
  )
}

const DivLine = ({ type }: { type: string }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          width: '100%',
          height: '1px',
          margin: '20px',
          background: type === '0' ? '#22B4E2' : '#0862A9'
        }}
      ></div>
    </div>
  )
}

const RowDataPart = ({
  delivery_datetime,
  delivery_car_model,
  delivery_car_number,
  delivery_location,
  customer_name,
  customer_tel,
  dispatch_type,
  comment,
  delivery_status,
  company_name
}: {
  delivery_datetime: number
  delivery_car_model: string
  delivery_car_number: string
  delivery_location: string
  customer_name: string
  customer_tel: string
  dispatch_type: string
  comment: string
  delivery_status: string
  company_name: string
}) => {
  const [isShowOptions, setShowOptions] = useState(false)

  return (
    <div>
      <ROW_ONE
        $show={isShowOptions}
        $type={dispatch_type}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: '20px'
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            background: dispatch_type === '0' ? '#22B4E2' : '#0862A9',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              width: 'calc(100% - 40px)',
              marginTop: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Text
              $size={'16px'}
              $weight={700}
              $color={'white'}
              style={{ margin: '0' }}
            >
              {dispatch_type === '0' ? '반납 대행' : '정비/사고/검사 대행'}
            </Text>
            <Text
              $size={'16px'}
              $weight={400}
              $color={'white'}
              style={{ margin: '0' }}
            >
              {moment(delivery_datetime)
                .add(-9, 'hours')
                .format('YYYY년 MM월 DD일 H시')}
            </Text>
          </div>

          <div
            style={{
              width: 'calc(100% - 40px)',
              height: '8px',
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <StepStatus status={delivery_status} />
          </div>

          <div
            style={{
              width: 'calc(100% - 40px)',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: 0
            }}
          >
            <StepText flex={'1'} msg={'카벨 확인'} />
            <StepText flex={'1.3'} msg={'파트너사\n 이동중'} />
            <StepText flex={'1'} msg={'입고'} />
            <StepText flex={'1'} msg={'상품화'} />
            <StepText flex={'1'} msg={'출고'} />
            <StepText flex={'1'} msg={'완료'} />
          </div>
        </div>

        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div
            style={{
              width: '100%',
              marginTop: '20px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Text
              $size={'14px'}
              $weight={600}
              $color={'#363636'}
              style={{ margin: '0', marginLeft: '20px' }}
            >
              차량 정보
            </Text>
            <Text
              $size={'14px'}
              $weight={400}
              $color={'#363636'}
              style={{ margin: '0', marginRight: '20px' }}
            >
              {`${delivery_car_model == null ? '-' : delivery_car_model}, ${delivery_car_number == null ? '-' : delivery_car_number}`}
            </Text>
          </div>

          <OneRowText
            title={'상태'}
            contents={getStatusType(delivery_status)}
            marginTop={'10px'}
            isLeft={false}
          />
        </div>
        {/*확장 영역*/}
        <div
          style={{
            display: isShowOptions ? 'block' : 'none',
            width: '100%'
          }}
        >
          <DivLine type={dispatch_type} />
          <OneRowText
            title={'정비소 정보'}
            contents={company_name == null ? '-' : company_name}
            marginTop={'0px'}
            isLeft={false}
          />
          <OneRowText
            title={'요청 사항'}
            contents={comment}
            marginTop={'10px'}
            isLeft={true}
          />
          <DivLine type={dispatch_type} />
          <OneRowText
            title={'임차인 정보'}
            contents={customer_name}
            marginTop={'10px'}
            isLeft={false}
          />
          <OneRowText
            title={''}
            contents={customer_tel}
            marginTop={'0px'}
            isLeft={false}
          />
          <OneRowText
            title={'픽업 위치'}
            contents={delivery_location}
            marginTop={'10px'}
            isLeft={false}
          />
        </div>
        {/* 확장 영역 */}
        <div
          style={{
            marginTop: '20px',
            marginBottom: '17px',
            cursor: 'pointer',
            display: 'flex'
          }}
          onClick={() => setShowOptions((prev) => !prev)}
        >
          <Text
            $size={'14px'}
            $weight={400}
            $color={'#949494'}
            style={{ margin: '0' }}
          >
            {isShowOptions ? '닫기' : '더보기'}
          </Text>
          <img
            style={{}}
            width={'22px'}
            height={'22px'}
            src={
              isShowOptions
                ? require('../assets/img/up_arrow_gray.png')
                : require('../assets/img/dn_arrow_gray.png')
            }
            alt='BigCo Inc. logo'
          />
        </div>
      </ROW_ONE>
    </div>
  )
}

const RowData = ({
  rowList
}: {
  rowList: {
    idx: number
    delivery_type: string
    delivery_datetime: number
    delivery_car_model: string
    delivery_car_number: string
    delivery_location: string
    customer_name: string
    customer_gender: string
    customer_tel: string
    dispatch_type: string
    comment: string
    company_idx: number
    delivery_status: string
    company_name: string
  }[]
}) => {
  return (
    <div>
      {rowList.map((it, index) => (
        <RowDataPart
          key={index}
          delivery_datetime={it.delivery_datetime}
          delivery_car_model={it.delivery_car_model}
          delivery_car_number={it.delivery_car_number}
          delivery_location={it.delivery_location}
          customer_name={it.customer_name}
          customer_tel={it.customer_tel}
          dispatch_type={it.dispatch_type}
          comment={it.comment}
          company_name={it.company_name}
          delivery_status={it.delivery_status}
        />
      ))}
    </div>
  )
}

interface IList {
  idx: number
  delivery_type: string
  delivery_datetime: number
  delivery_car_model: string
  delivery_car_number: string
  delivery_location: string
  customer_name: string
  customer_gender: string
  customer_tel: string
  dispatch_type: string
  comment: string
  company_idx: number
  delivery_status: string
  company_name: string
}
const ReqDataM = ({
  cmpIdx,
  token,
  navigate
}: {
  cmpIdx: number
  token: string
  navigate: NavigateFunction
}) => {
  const dispatch = useDispatch()
  const endTarget = useRef(null)
  const [loading, setLoading] = useState(false)
  // const [page, setPage] = useState(1);  // page는 state가 아닌 아래 useRef를 통해 사용
  const page = useRef(1)
  const companyIndex = useRef(cmpIdx)
  const [datas, setDatas] = useState<IList[]>([])

  useEffect(() => {
    endObserver.observe(endTarget.current!) // observer를 등록하는건 동일
  }, [])

  const endObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) return
      if (loading) return
      pagingNext(navigate)
      page.current += 1 // 이렇게 해줘야 page 숫자가 올라간다.
    })
  })

  function pagingNext(navigate: NavigateFunction) {
    setLoading(true)

    const params = {
      idx: companyIndex.current,
      pageNum: page.current.valueOf()
    }

    fetch(`${SERVER_URL()}/lists`, {
      method: 'POST', //메소드 지정
      headers: {
        //데이터 타입 지정
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(params)
    })
      .then((res) => res.json()) // 리턴값이 있으면 리턴값에 맞는 req 지정
      .then((res) => {
        //const result = JSON.stringify(res)
        if (res.code == 0) {
          //성공

          res.data.map((it) =>
            setDatas((datas) => {
              return [
                ...datas,
                {
                  idx: it.idx,
                  delivery_type: it.delivery_type,
                  delivery_datetime: it.delivery_datetime,
                  delivery_car_model: it.delivery_car_model,
                  delivery_car_number: it.delivery_car_number,
                  delivery_location: it.delivery_location,
                  customer_name: it.customer_name,
                  customer_gender: it.customer_gender,
                  customer_tel: it.customer_tel,
                  dispatch_type: it.dispatch_type,
                  comment: it.comment,
                  company_idx: it.company_idx,
                  delivery_status: it.delivery_status,
                  company_name: it.company_name
                }
              ]
            })
          )
        } else {
          dispatch(initToken())
          navigate('/')
        }
      })

    setLoading(false)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <RowData rowList={datas} />
      <div
        id='scrollEnd'
        style={{ height: '20px', backgroundColor: 'transparent' }}
        ref={endTarget}
      ></div>
    </div>
  )
}

export default ReqDataM
