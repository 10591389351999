import { NavigateFunction } from 'react-router-dom'
import moment from 'moment/moment'
import { Dispatch } from 'redux'
import { initToken } from '../redux/reducers/AuthReducer'

export const getGenderType = (gender: string) => {
  return gender === '여성' ? '1' : '0'
}

export const getDispatchType = (dispatch: string) => {
  switch (dispatch) {
    case '일반 단기':
      return '0'
    case '일반 장기':
      return '1'
    case '보험 대차':
      return '2'
    case '기타':
      return '3'
    default:
      return '0'
  }
}

export const getStatusType = (process: string) => {
  switch (process) {
    case '0':
      return '카벨 확인'
    case '1':
      return '파트너 사 이동 중'
    case '2':
      return '입고'
    case '3':
      return '상품화'
    case '4':
      return '출고'
    case '5':
      return '완료'
    default:
      return '카벨 확인'
  }
}

export const SERVER_URL = () => {
  //return 'http://192.168.0.5:6060'
  //return 'http://43.201.62.70:6066'
  return 'https://server.carbell.co.kr'
}

export const NEW_SERVER_URL = () => {
  return 'https://admin-server.carbell.co.kr/v1'
}

export const API_URL = () => {
  return 'https://api-carbell.kodkodkod.studio'
}

export const IS_VALID_TOKEN = (token) => {
  return (
    token != null &&
    token.data != null &&
    token.data.auth_token != null &&
    token.data.auth_token.length > 0
  )
}

export const ValidCheck = (
  type: number,
  date: string,
  time: string,
  carType: string,
  carNumber: string,
  addr: string,
  name: string,
  gender: string | undefined,
  phone: string,
  dispatch: string,
  cmpName: string,
  etc: string,
  id: number,
  nav: NavigateFunction,
  token: string,
  patch: Dispatch,
  registEvent: (result: boolean) => void
) => {
  alert('in')
  return
  if (!type) {
    // 반납 대행
    if (
      date === '신청 일시' ||
      time === '시간' ||
      carType.length == 0 ||
      carNumber.length == 0 ||
      addr.length == 0 ||
      name.length == 0 ||
      gender === '성별' ||
      phone.length == 0
    ) {
      alert('반납 대행 필수 입력 실패')
      return
    }
  } else {
    // 정비 대행
    if (
      carType.length == 0 ||
      carNumber.length == 0 ||
      name.length == 0 ||
      gender === '성별' ||
      phone.length == 0
    ) {
      alert('[정비/사고/검사 대행] 필수 입력 실패')
      return
    }
  }

  const params = {
    // 필요한 query params를 {} 형태에 담아준다.
    delivery_type: type.toString(),
    datetime:
      time !== '시간'
        ? moment(new Date(date)).format('YYYY-MM-DD ') + time.split(' ')[0]
        : moment(new Date()).format('YYYY-MM-DD ') + '09',
    car_model: carType,
    car_number: carNumber,
    location: addr,
    name: name,
    gender: getGenderType(gender as string),
    tel: phone,
    dispatch_type: getDispatchType(dispatch),
    comment: etc,
    id: id
  }

  const params_to_slack = {
    // 필요한 query params를 {} 형태에 담아준다.
    delivery_type: type.toString(),
    datetime:
      time !== '시간'
        ? moment(new Date(date)).format('YYYY-MM-DD ') + time.split(' ')[0]
        : moment(new Date()).format('YYYY-MM-DD ') + '09',
    car_model: carType,
    car_number: carNumber,
    location: addr,
    name: name,
    gender: getGenderType(gender as string),
    tel: phone,
    dispatch_type: dispatch,
    cmpName: cmpName,
    comment: etc,
    id: id
  }

  fetch(`${SERVER_URL()}/register`, {
    method: 'POST', //메소드 지정
    headers: {
      //데이터 타입 지정
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(params)
  })
    .then((res) => res.json()) // 리턴값이 있으면 리턴값에 맞는 req 지정
    .then(async (res) => {
      if (res.code == 0) {
        //console.log('@#@!#!@#!#')

        try {
          const ticketResponse = await fetch(`${SERVER_URL()}/createTicket`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json; charset=utf-8'
              //Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(params)
          })

          const ticketResult = await ticketResponse.json()
          if (ticketResponse.ok && ticketResult.code === 0) {
            console.log('Ticket created successfully.')
          } else {
            console.error('Error creating ticket:', ticketResult.message)
          }
        } catch (e) {
          console.log(e)
        }

        sendRegSlackMessage(params_to_slack)
        registEvent(true) //nav('/list')
      } else {
        patch(initToken())
        nav('/')
      }
    })
}

const SLACK_REGIST_WEBHOOK_URL =
  'https://hooks.slack.com/services/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'

const SLACK_BUY_WEBHOOK_URL =
  'https://hooks.slack.com/services/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'

export const sendRegSlackMessage = ({
  delivery_type,
  datetime,
  car_model,
  car_number,
  location,
  name,
  tel,
  dispatch_type,
  cmpName,
  comment
}: {
  delivery_type: string
  datetime: string
  car_model: string
  car_number: string
  location: string
  name: string
  tel: string
  dispatch_type: string
  cmpName: string
  comment: string
}) => {
  // const params = {
  //   // 필요한 query params를 {} 형태에 담아준다.
  //   delivery_type: delivery_type,
  //   datetime: datetime,
  //   car_model: car_model,
  //   car_number: car_number,
  //   location: location,
  //   name: name,
  //   gender: gender,
  //   tel: tel,
  //   dispatch_type: dispatch_type,
  //   comment: comment,
  //   id: id
  // }

  const type = delivery_type == '0' ? '반납대행' : '정비/사고/검사 대행'
  const path_type = dispatch_type == '배차 선택' ? '-' : dispatch_type
  const sendMsg =
    '1. 회사명 : ' +
    cmpName +
    '\r\n' +
    '2. 상품 : ' +
    type +
    '\r\n' +
    '3. 신청일시 : ' +
    datetime +
    '\r\n' +
    '4. 차량정보 : ' +
    car_model +
    ' ' +
    car_number +
    '\r\n' +
    '5. 위치 : ' +
    location +
    '\r\n' +
    '6. 임차인 정보 : ' +
    name +
    ' / ' +
    tel +
    '\r\n' +
    '7. 배차 형태 : ' +
    path_type +
    '\r\n' +
    '8. 요청사항 : ' +
    comment +
    '\r\n'
  //console.log('@@@@@@ 222 params ' + delivery_type)
  //console.log('@@@@@@ 333 params ' + sendMsg)
  // console.log(JSON.stringify({ text: JSON.stringify(params) }))
  // console.log(JSON.stringify(params))

  fetch(SLACK_REGIST_WEBHOOK_URL, {
    method: 'POST', //메소드 지정
    headers: {
      //데이터 타입 지정
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: JSON.stringify({ text: sendMsg })
  })
    .then((res) => res) // 리턴값이 있으면 리턴값에 맞는 req 지정
    .then((res) => {
      //console.log('@@@@@')
      console.log(res)
    })
}

export const sendBuySlackMessage = (company_name: string, phone: string) => {
  const params = {
    company_name: company_name,
    company_tel: phone
  }

  // console.log('@@@@@@ 11 params ' + phone)
  // console.log(JSON.stringify({ text: JSON.stringify(params) }))
  // console.log(JSON.stringify(params))
  fetch(SLACK_BUY_WEBHOOK_URL, {
    method: 'POST', //메소드 지정
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: JSON.stringify({ text: JSON.stringify(params) })
  })
    .then((res) => res) // 리턴값이 있으면 리턴값에 맞는 req 지정
    .then((res) => {
      //console.log('@@@@@123123')
      console.log(res)
    })
}
