import Footer from './Footer'
import Header from './Header'
import styled from 'styled-components'
import React from 'react'

const Main = styled.main`
  width: 100%;
`

const LayoutLong = ({
  children,
  height
}: {
  children: React.ReactNode
  height: number
}) => {
  return (
    <>
      <div>
        <Header isLong={true} />

        <div
          onClick={() => {}}
          style={{
            width: '100%',
            height: `${height}px`,
            background: 'white',
            marginTop: '0px',
            marginBottom: '0px',
            top: '60px',
            right: '0px',
            margin: 'auto'
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100%'
            }}
          >
            <div
              onClick={() => {}}
              style={{
                float: 'left',
                width: '100%',
                height: '180px',
                background: '#0862A9'
              }}
            />

            <div
              style={{
                float: 'left',
                width: '100%',
                height: '70%',
                background: '#FFFFFF'
              }}
            />
          </div>
          <Main>{children}</Main>
        </div>
        <Footer />
      </div>
    </>
  )
}
export default LayoutLong
