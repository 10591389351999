import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { initToken } from '../redux/reducers/AuthReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducer } from '../redux/reducers/RootReducer'
import { IS_VALID_TOKEN } from './Common'

const HeaderList = styled.div<{ $isLong?: boolean }>`
  position: ${(props) => (props.$isLong ? '' : 'fixed')};
  left: 0;
  top: 0;
  width: 100%;
  height: 60px;
  background: #ffffff;
  justify-content: center;
  align-items: center;
  display: flex;
`

const MenuText = styled.li`
  font-size: 16px;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`
const Header = ({ isLong }: { isLong: boolean }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [validToken, setValidToken] = useState(false)
  const token = useSelector((state: RootReducer) => state.Auth.token)
  useEffect(() => {
    const infos = JSON.parse(token)
    if (!IS_VALID_TOKEN(infos)) navigate('/')
    else {
      setValidToken(true)
    }
  }, [])

  return (
    <HeaderList $isLong={isLong}>
      <div
        style={{
          width: 'calc(100vw - 600px)',
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <img
            onClick={() => navigate('/')}
            width={'98px'}
            height={'28px'}
            src={require('../assets/img/logo.png')}
            alt='BigCo Inc. logo'
            style={{ cursor: 'pointer' }}
          />
        </div>

        <nav
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'right'
          }}
        >
          <ul style={{ listStyle: 'none' }}>
            <MenuText
              style={{
                float: 'left',
                fontFamily: 'Pretendard',
                fontSize: '14px',
                marginRight: '56px',
                cursor: 'pointer',
                display: validToken ? 'block' : 'none'
              }}
              onClick={() => {
                navigate('/member')
              }}
            >
              마이페이지
            </MenuText>
            <MenuText
              onClick={() => {
                dispatch(initToken())
                navigate('/')
              }}
              style={{
                float: 'left',
                fontSize: '14px',
                cursor: 'pointer'
              }}
            >
              {validToken ? '로그아웃' : '로그인'}
            </MenuText>
          </ul>
        </nav>
      </div>
    </HeaderList>
  )
}

export default Header
