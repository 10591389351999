import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LayoutM from '../../components/LayoutM'
import CustomCalendarM from '../../components/CalendarM'
import { useNavigate } from 'react-router-dom'

import { IS_VALID_TOKEN, SERVER_URL, ValidCheck } from '../../components/Common'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducer } from '../../redux/reducers/RootReducer'
import { buyMsg, ConfirmPopUpM } from '../../components/popup/PopUp'

const Button = styled.button<{
  $width?: string
  $height?: string
  $color?: string
  $flex?: number
}>`
  width: ${(props) => (props.$width ? props.$width : '0px')};
  height: ${(props) => (props.$height ? props.$height : '0px')};
  font-family: 'Pretendard';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  background: ${(props) => (props.$color ? props.$color : 'white')};
  color: white;
  border: none;
  border-radius: 10px;
  flex: ${(props) => (props.$flex ? props.$flex : 'none')};
`
const RowDiv = styled.div`
  width: 100%;
`
const BorderInput = styled.input`
  margin: 0;
  width: 100%;
  height: 36px;
  background: #fdfdfd;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  &:focus {
    border-color: #22b4e2;
    transition: border-color 0.3s ease-in-out;
    outline: 0;
  }
  color: #333333;
  padding-right: 10px;
  padding-left: 10px;
  font-family: 'Pretendard';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #e5e5e5;
`

const DivLine = ({ top, bottom }: { top: string; bottom: string }) => {
  return (
    <Line style={{ marginTop: top, marginBottom: bottom }}>
      <p />
    </Line>
  )
}

const Text = styled.p<{ $size?: string; $weight?: number; $color?: string }>`
  font-family: 'Pretendard';
  font-size: ${(props) => (props.$size ? props.$size : '20px')};
  font-style: normal;
  font-weight: ${(props) => (props.$weight ? props.$weight : 400)};
  line-height: 26px;
  color: ${(props) => (props.$color ? props.$color : '#191919')};
`

const TextArea = styled.textarea`
  width: 100%;
  height: 72px;
  margin: 0;
  resize: none;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 12px;
  font-family: 'Pretendard ';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-radius: 5px;
  background: #fdfdfd;
  border: 1px solid #e5e5e5;
  &:focus {
    border-color: #22b4e2;
    transition: border-color 0.3s ease-in-out;
    outline: 0;
  }
`
const SelectBox = styled.div<{ $show?: boolean }>`
  position: relative;
  width: 240px;
  height: 36px;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  background-color: #fdfdfd;
  align-self: center;
  cursor: pointer;
  align-items: center;
  display: flex;
`
const Label = styled.label`
  margin-left: 20px;
  text-align: center;
  font-family: 'Pretendard';
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`
const SelectOptions = styled.ul<{ $show?: boolean; $height?: string }>`
  position: absolute;
  list-style: none;
  top: 36px;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: ${(props) => props.$height};
  max-height: ${(props) => (props.$show ? 'none' : '0')};
  padding: 0;
  border-radius: 8px;
  background-color: #fdfdfd;
  border: ${(props) =>
    props.$show ? '1px solid #e5e5e5' : '1px solid transparent'};
  color: #fefefe;
`
const Option = styled.li`
  position: relative;
  padding-left: 20px;
  height: 36px;
  transition: background-color 0.2s ease-in;
  background-color: transparent;
  display: flex;
  color: #363636;
  font-family: 'Pretendard';
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: #22b4e2;
  }
`

const CustomSelect = ({
  left,
  currentValue,
  setCurrentValue,
  options
}: {
  left: string
  currentValue: string
  setCurrentValue: React.Dispatch<React.SetStateAction<string>>
  options: string[]
}) => {
  const [isSelected, setIsSelected] = useState(false)
  const [isShowOptions, setShowOptions] = useState(false)

  const handleOnChangeSelectValue = (e) => {
    const { innerText } = e.target
    setIsSelected(true)
    setCurrentValue(innerText)
  }

  return (
    <SelectBox
      style={{ marginLeft: left }}
      $show={isShowOptions}
      onClick={() => setShowOptions((prev) => !prev)}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <Label
          style={{
            flex: 1,
            textAlign: 'left',
            color: isSelected ? '#333333' : '#949494'
          }}
        >
          {currentValue}
        </Label>
        <img
          width={'18px'}
          height={'18px'}
          src={
            isShowOptions
              ? require('../../assets/img/up-arrow.png')
              : require('../../assets/img/down-arrow.png')
          }
          alt='BigCo Inc. logo'
          style={{ marginRight: '20px' }}
        />
      </div>
      <SelectOptions $show={isShowOptions}>
        {options.map((option, index) => (
          <Option key={index} onClick={handleOnChangeSelectValue}>
            {option}
          </Option>
        ))}
      </SelectOptions>
    </SelectBox>
  )
}

const CustomTime = ({
  left,
  currentValue,
  setCurrentValue
}: {
  left: string
  currentValue: string
  setCurrentValue: React.Dispatch<React.SetStateAction<string>>
}) => {
  const [isSelected, setIsSelected] = useState(false)
  const [isShowOptions, setShowOptions] = useState(false)

  const handleOnChangeSelectValue = (e) => {
    const { innerText } = e.target
    setIsSelected(true)
    setCurrentValue(innerText)
  }

  return (
    <SelectBox
      style={{ marginLeft: left }}
      $show={isShowOptions}
      onClick={() => setShowOptions((prev) => !prev)}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <Label
          style={{
            flex: 1,
            textAlign: 'left',
            color: isSelected ? '#333333' : '#949494'
          }}
        >
          {currentValue}
        </Label>
        <img
          width={'18px'}
          height={'18px'}
          src={
            isShowOptions
              ? require('../../assets/img/up-arrow.png')
              : require('../../assets/img/down-arrow.png')
          }
          alt='BigCo Inc. logo'
          style={{ marginRight: '20px' }}
        />
      </div>
      <SelectOptions
        $show={isShowOptions}
        $height={'432px'}
        style={{ zIndex: 100 }}
      >
        {Array.from({ length: 12 }, (_, i) => i + 9).map((hour) => (
          <Option key={hour} onClick={handleOnChangeSelectValue}>
            {`${hour.toString().padStart(2, '0')} 시`}
          </Option>
        ))}
      </SelectOptions>
    </SelectBox>
  )
}

const ReturnRegistM = () => {
  const navigate = useNavigate()
  const patch = useDispatch()

  const [value, onChange] = useState(new Date())
  const [time, setTime] = useState('시간')
  const [regType, setRegType] = useState(0)
  const [carType, setCarType] = useState('')
  const [carNumber, setCarNumber] = useState('')
  const [addr, setAddr] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [dispatch, setDispatch] = useState('배차 선택')
  const [etc, setEtc] = useState('')
  const [ticketCount, setTicketCount] = useState(0)
  const [, setValidToken] = useState(false)
  const [show, setShow] = useState(false)

  const token = useSelector((state: RootReducer) => state.Auth.token)
  const infos = JSON.parse(token)

  useEffect(() => {
    const infos = JSON.parse(token)
    if (!IS_VALID_TOKEN(infos)) navigate('/login')
    else {
      setValidToken(true)

      const params = {
        id: infos.data.idx
      }

      fetch(`${SERVER_URL()}/user/info`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${infos.data.auth_token}`
        },
        body: JSON.stringify(params)
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.code == 0) {
            setTicketCount(res.data.ticket_count)
          }
        })
    }
  }, [])

  const confirmClicked = () => {
    setShow(false)
    navigate('/list')
  }

  const registResult = (result: boolean) => {
    if (result) {
      if (ticketCount <= 0) setShow(true)
      else navigate('/list')
    }
  }

  const saveCarType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCarType(event.target.value)
  }

  const saveCarNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCarNumber(event.target.value)
  }

  const saveAddr = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddr(event.target.value)
  }

  const saveName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const savePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value)
  }

  const saveEtc = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEtc(event.target.value)
  }

  const getServiceInfoLabel = () => {
    switch (regType) {
      case 0:
        return '서비스 정보'
      case 1:
        return '서비스 정보'
      case 2:
        return '서비스 정보'
      case 3:
        return '서비스 정보'
      default:
        return '서비스 정보'
    }
  }

  const getDateTimeLabel = () => {
    switch (regType) {
      case 0:
        return '서비스 신청 일시'
      case 1:
        return '서비스 신청 일시'
      case 2:
        return '자동차 검사 유효기간'
      case 3:
        return '반납 신청 일시'
      default:
        return '서비스 신청 일시'
    }
  }

  const getCarInfoLabel = () => {
    switch (regType) {
      case 0:
      case 1:
      case 2:
      case 3:
        return '서비스 차량 정보'
      default:
        return '서비스 차량 정보'
    }
  }

  const getLocationLabel = () => {
    switch (regType) {
      case 0:
        return '서비스 차량 위치'
      case 1:
        return '서비스 차량 위치 (사고 현장 위치)'
      case 2:
        return '서비스 차량 위치'
      case 3:
        return '서비스 차량 위치'
      default:
        return '서비스 차량 위치'
    }
  }

  const getRentalInfoLabel = () => '임대차 계약 정보'

  const getDispatchLabel = () => {
    switch (regType) {
      case 0:
        return '배차 형태'
      case 1:
        return '사고 유형'
      case 2:
        return '자동차 검사 유형'
      case 3:
        return '배차 형태'
      default:
        return '배차 형태'
    }
  }

  const getDispatchOptions = () => {
    switch (regType) {
      case 0:
      case 3:
        return ['일반 단기', '일반 장기', '기타']
      case 1:
        return ['가해', '피해', '과실', '단독', '기타(원인불명 등)']
      case 2:
        return ['정기', '종합', '연장', '차령']
      default:
        return ['일반 단기', '일반 장기', '기타']
    }
  }

  const getRequestPlaceholder = () => {
    switch (regType) {
      case 0:
        return '엔진 오일 교환 필요 등'
      case 1:
        return '서비스 상세 요청 사항 (예: 제휴 사고수리 공장으로 입고 후 견적 확인 및 차량 보관)'
      case 2:
        return '서비스 상세 요청 사항 (예: 자동차 검사 유효기간 만료 임박 등)'
      case 3:
        return '서비스 상세 요청 사항 (예: 전체 점검 및 경정비 필요, 실내 흡연 여/부 파악 요청)'
      default:
        return '서비스 상세 요청 사항'
    }
  }

  const getImageUploader = () => {
    switch (regType) {
      case 0:
        return (
          <div
            style={{
              margin: 0,
              display: 'flex',
              flexDirection: 'column',
              marginTop: '10px',
              marginBottom: '20px'
            }}
          >
            <Text
              $size={'12px'}
              $weight={400}
              $color={'#949494'}
              style={{ margin: 0 }}
            >
              정비 요청 사진 업로드
            </Text>
            <input type='file' multiple />
          </div>
        )
      case 1:
        return (
          <div
            style={{
              margin: 0,
              display: 'flex',
              flexDirection: 'column',
              marginTop: '10px',
              marginBottom: '20px'
            }}
          >
            <Text
              $size={'12px'}
              $weight={400}
              $color={'#949494'}
              style={{ margin: 0 }}
            >
              차량 파손 사진 업로드
            </Text>
            <input type='file' multiple />
          </div>
        )
      default:
        return <></>
    }
  }

  return (
    <>
      <LayoutM myPage={true}>
        <div
          style={{
            width: '100%',
            height: '100%',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <DivLine bottom={'21px'} top={'0px'} />
          <div
            style={{
              width: 'calc(100vw - 44px)',
              height: '100%'
            }}
          >
            <div
              style={{
                width: '100%',
                flexDirection: 'column',
                justifyContent: 'center',
                display: 'flex'
              }}
            >
              <Text $size={'16px'} $weight={400} style={{ margin: '0' }}>
                반납 신청
              </Text>
              <DivLine bottom={'20px'} top={'19px'} />

              <RowDiv
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <Text $size={'16px'} $weight={700} style={{ margin: 0 }}>
                  상품 선택
                </Text>
                <Text
                  $size={'10px'}
                  $weight={400}
                  $color={'#0862A9'}
                  style={{ margin: 0, marginLeft: '4px' }}
                >
                  (*필수)
                </Text>
              </RowDiv>

              <RowDiv
                style={{
                  cursor: 'pointer',
                  flexDirection: 'column',
                  display: 'flex',
                  marginTop: '20px'
                }}
              >
                <div
                  onClick={() => setRegType(0)}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '10px'
                  }}
                >
                  <img
                    width={'16px'}
                    height={'16px'}
                    src={
                      regType == 0
                        ? require('../../assets/img/on.png')
                        : require('../../assets/img/off.png')
                    }
                    alt='BigCo Inc. logo'
                  />
                  <Text
                    $size={'14px'}
                    $weight={400}
                    style={{ margin: 0, marginLeft: '8px' }}
                  >
                    경정비 서비스
                  </Text>
                </div>

                <div
                  onClick={() => setRegType(1)}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '10px'
                  }}
                >
                  <img
                    width={'16px'}
                    height={'16px'}
                    src={
                      regType == 1
                        ? require('../../assets/img/on.png')
                        : require('../../assets/img/off.png')
                    }
                    alt='BigCo Inc. logo'
                  />
                  <Text
                    $size={'14px'}
                    $weight={400}
                    style={{ margin: 0, marginLeft: '8px' }}
                  >
                    사고수리 서비스
                  </Text>
                </div>

                <div
                  onClick={() => setRegType(2)}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '10px'
                  }}
                >
                  <img
                    width={'16px'}
                    height={'16px'}
                    src={
                      regType == 2
                        ? require('../../assets/img/on.png')
                        : require('../../assets/img/off.png')
                    }
                    alt='BigCo Inc. logo'
                  />
                  <Text
                    $size={'14px'}
                    $weight={400}
                    style={{ margin: 0, marginLeft: '8px' }}
                  >
                    자동차 검사 서비스
                  </Text>
                </div>

                <div
                  onClick={() => setRegType(3)}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <img
                    width={'16px'}
                    height={'16px'}
                    src={
                      regType == 3
                        ? require('../../assets/img/on.png')
                        : require('../../assets/img/off.png')
                    }
                    alt='BigCo Inc. logo'
                  />
                  <Text
                    $size={'14px'}
                    $weight={400}
                    style={{ margin: 0, marginLeft: '8px' }}
                  >
                    렌터카 반납 서비스
                  </Text>
                </div>
              </RowDiv>

              <DivLine bottom={'20px'} top={'32px'} />

              <Text
                $size={'16px'}
                $weight={700}
                style={{ margin: 0, marginBottom: '20px' }}
              >
                {getServiceInfoLabel()}
              </Text>

              <RowDiv
                style={{
                  flexDirection: 'column',
                  display: 'flex'
                }}
              >
                <div style={{ margin: 0, display: 'flex' }}>
                  <Text $size={'14px'} $weight={400} style={{ margin: 0 }}>
                    {getDateTimeLabel()}
                  </Text>
                  <Text
                    $size={'10px'}
                    $weight={400}
                    $color={'#0862A9'}
                    style={{
                      margin: 0,
                      marginLeft: '4px',
                      display: regType !== 0 && regType !== 1 ? 'block' : 'none'
                    }}
                  >
                    (*필수)
                  </Text>
                </div>

                <div
                  style={{
                    margin: 0,
                    display: 'flex',
                    marginTop: '8px'
                  }}
                >
                  <CustomCalendarM
                    value={value}
                    onChange={onChange}
                    serviceType={
                      regType === 0 ? '경정비' : regType === 1 && '사고수리'
                    }
                  />
                  {regType === 3 && (
                    <CustomTime
                      left={'10px'}
                      currentValue={time}
                      setCurrentValue={setTime}
                    />
                  )}
                </div>
              </RowDiv>

              <RowDiv
                style={{
                  flexDirection: 'column',
                  display: 'flex'
                }}
              >
                <div style={{ margin: 0, display: 'flex', marginTop: '20px' }}>
                  <Text $size={'14px'} $weight={400} style={{ margin: 0 }}>
                    {getCarInfoLabel()}
                  </Text>
                  <Text
                    $size={'10px'}
                    $weight={400}
                    $color={'#0862A9'}
                    style={{ margin: 0, marginLeft: '4px' }}
                  >
                    (*필수)
                  </Text>
                </div>

                <div
                  style={{ margin: 0, display: 'flex', marginBottom: '8px' }}
                >
                  <Text
                    $size={'12px'}
                    $weight={400}
                    $color={'#949494'}
                    style={{ margin: 0 }}
                  >
                    차종, 차량 번호
                  </Text>
                  <Text
                    $size={'14px'}
                    $weight={400}
                    $color={'#949494'}
                    style={{ margin: 0 }}
                  >
                    (ex. 투싼, 11가 2222)
                  </Text>
                </div>

                <div style={{ margin: 0, display: 'flex' }}>
                  <BorderInput
                    placeholder={'차종'}
                    value={carType}
                    onChange={saveCarType}
                    style={{}}
                  ></BorderInput>
                  <BorderInput
                    placeholder={'차량 번호'}
                    value={carNumber}
                    onChange={saveCarNumber}
                    style={{ marginLeft: '10px' }}
                  ></BorderInput>
                </div>
              </RowDiv>

              <RowDiv
                style={{
                  marginTop: '20px'
                }}
              >
                <div style={{ margin: 0, display: 'flex' }}>
                  <Text $size={'14px'} $weight={400} style={{ margin: 0 }}>
                    {getLocationLabel()}
                  </Text>
                </div>

                <div style={{ margin: 0, display: 'flex' }}>
                  <Text
                    $size={'12px'}
                    $weight={400}
                    $color={'#949494'}
                    style={{ margin: 0 }}
                  >
                    읍, 면, 동까지 입력
                  </Text>
                  <Text
                    $size={'14px'}
                    $weight={400}
                    $color={'#949494'}
                    style={{ margin: 0 }}
                  >
                    (ex. 서울시 강남구 청담동)
                  </Text>
                </div>

                <div style={{ margin: 0, display: 'flex', marginTop: '8px' }}>
                  <BorderInput
                    placeholder={'읍, 면, 동까지 입력'}
                    value={addr}
                    onChange={saveAddr}
                    style={{}}
                  ></BorderInput>
                </div>
              </RowDiv>

              <DivLine top={'30px'} bottom={'20px'} />

              <RowDiv style={{ marginBottom: '30px' }}>
                <Text $size={'20px'} $weight={700} style={{ margin: 0 }}>
                  {getRentalInfoLabel()}
                </Text>
              </RowDiv>

              <RowDiv style={{}}>
                <div style={{ margin: 0, display: 'flex' }}>
                  <Text $size={'14px'} $weight={400} style={{ margin: 0 }}>
                    임차인 정보
                  </Text>
                  <Text
                    $size={'10px'}
                    $weight={400}
                    $color={'#0862A9'}
                    style={{ margin: 0, marginLeft: '4px' }}
                  >
                    (*필수)
                  </Text>
                </div>

                <div
                  style={{
                    margin: 0,
                    display: 'flex',
                    marginTop: '10px',
                    marginBottom: '20px'
                  }}
                >
                  <BorderInput
                    placeholder={'이름'}
                    value={name}
                    onChange={saveName}
                    style={{}}
                  ></BorderInput>
                </div>
              </RowDiv>

              <RowDiv style={{}}>
                <div style={{ margin: 0, display: 'flex' }}>
                  <Text $size={'14px'} $weight={400} style={{ margin: 0 }}>
                    임차인 연락처
                  </Text>
                  <Text
                    $size={'10px'}
                    $weight={400}
                    $color={'#0862A9'}
                    style={{ margin: 0, marginLeft: '4px' }}
                  >
                    (*필수)
                  </Text>
                </div>

                <div
                  style={{
                    margin: 0,
                    display: 'flex',
                    marginTop: '10px',
                    marginBottom: '19px'
                  }}
                >
                  <BorderInput
                    placeholder={'010-0000-0000'}
                    value={phone}
                    onChange={savePhone}
                    style={{}}
                  ></BorderInput>
                </div>
              </RowDiv>

              <RowDiv style={{}}>
                <div style={{ margin: 0, display: 'flex' }}>
                  <Text $size={'14px'} $weight={400} style={{ margin: 0 }}>
                    {getDispatchLabel()}
                  </Text>
                  {(regType === 1 || regType === 2) && (
                    <Text
                      $size={'10px'}
                      $weight={400}
                      $color={'#0862A9'}
                      style={{ margin: 0, marginLeft: '4px' }}
                    >
                      (*필수)
                    </Text>
                  )}
                </div>

                <div
                  style={{
                    margin: 0,
                    display: 'flex',
                    marginTop: '10px'
                  }}
                >
                  <CustomSelect
                    left={'0px'}
                    currentValue={dispatch}
                    setCurrentValue={setDispatch}
                    options={getDispatchOptions()}
                  />
                </div>
              </RowDiv>

              <DivLine top={'31px'} bottom={'19px'} />

              <RowDiv style={{}}>
                <div style={{ margin: 0, display: 'flex' }}>
                  <Text $size={'14px'} $weight={400} style={{ margin: 0 }}>
                    요청 사항
                  </Text>
                </div>

                <div
                  style={{
                    margin: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '10px'
                  }}
                >
                  <TextArea
                    rows={5}
                    cols={35}
                    placeholder={getRequestPlaceholder()}
                    value={etc}
                    onChange={saveEtc}
                  />
                  {getImageUploader()}
                </div>
              </RowDiv>
            </div>

            <Button
              onClick={() =>
                ValidCheck(
                  regType,
                  value.toString(),
                  time,
                  carType,
                  carNumber,
                  addr,
                  name,
                  '',
                  phone,
                  dispatch,
                  infos != null ? infos.data.company_name : '',
                  etc,
                  infos != null ? infos.data.idx : 0,
                  navigate,
                  infos != null ? infos.data.auth_token : '',
                  patch,
                  registResult
                )
              }
              $width={'100%'}
              $height={'48px'}
              $color={'#22B4E2'}
              style={{ marginBottom: '30px', marginTop: '60px' }}
            >
              신청하기
            </Button>
          </div>
        </div>
      </LayoutM>
      <>
        {' '}
        {show && <ConfirmPopUpM errMsg={buyMsg} clickEvent={confirmClicked} />}
      </>
    </>
  )
}

export default ReturnRegistM
