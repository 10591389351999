import './ReturnRegist.scss'
import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootReducer } from '../redux/reducers/RootReducer'
import CarInfo from '../components/CarInfo'
import CustomCalendar from '../components/Calendar'
import {
  IS_VALID_TOKEN,
  SERVER_URL,
  NEW_SERVER_URL, API_URL
} from '../components/Common'
import { useNavigate } from 'react-router-dom'
import LayoutLong from '../components/LayoutLong'
import { buyMsg, ConfirmPopUp } from '../components/popup/PopUp'
import axios from 'axios'

const RowDiv = styled.div`
  width: 770px;
`

const DivLine = ({ top, bottom }: { top: string; bottom: string }) => {
  return (
    <Line style={{ marginTop: top, marginBottom: bottom }}>
      <p />
    </Line>
  )
}

const Line = styled.div`
  width: 770px;
  height: 1px;
  background: #e5e5e5;
`

const Text = styled.p<{ $size?: string; $weight?: number; $color?: string }>`
  font-family: 'Pretendard';
  font-size: ${(props) => (props.$size ? props.$size : '20px')};
  font-style: normal;
  font-weight: ${(props) => (props.$weight ? props.$weight : 400)};
  line-height: 26px;
  color: ${(props) => (props.$color ? props.$color : '#191919')};
`
const BorderInput = styled.input`
  margin: 0;
  width: 240px;
  height: 54px;
  background: #fdfdfd;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  &:focus {
    border-color: #22b4e2;
    transition: border-color 0.3s ease-in-out;
    outline: 0;
  }
  color: #333333;
  padding-right: 10px;
  padding-left: 10px;
  font-family: 'Pretendard';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
`

const TextArea = styled.textarea`
  width: 478px;
  height: 108px;
  margin: 0;
  resize: none;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 12px;
  font-family: 'Pretendard ';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  border-radius: 5px;
  background: #fdfdfd;
  border: 1px solid #e5e5e5;
  &:focus {
    border-color: #22b4e2;
    transition: border-color 0.3s ease-in-out;
    outline: 0;
  }
`
const SelectBox = styled.div<{ $show?: boolean }>`
  position: relative;
  width: 200px;
  height: 54px;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  background-color: #fdfdfd;
  align-self: center;
  cursor: pointer;
  align-items: center;
  display: flex;
`
const Label = styled.label`
  margin-left: 20px;
  text-align: center;
  font-family: 'Pretendard';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
`
const SelectOptions = styled.ul<{ $show?: boolean; $height?: string }>`
  position: absolute;
  list-style: none;
  top: 50px;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: ${(props) => props.$height};
  max-height: ${(props) => (props.$show ? 'none' : '0')};
  padding: 0;
  border-radius: 8px;
  background-color: #fdfdfd;
  border: ${(props) =>
    props.$show ? '1px solid #e5e5e5' : '1px solid transparent'};
  color: #fefefe;
`
const Option = styled.li`
  position: relative;
  padding-left: 20px;
  height: 54px;
  transition: background-color 0.2s ease-in;
  background-color: transparent;
  display: flex;
  color: #363636;
  font-family: 'Pretendard';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: #22b4e2;
  }
`

const CustomSelect = ({
  left,
  currentValue,
  setCurrentValue,
  options
}: {
  left: string
  currentValue: string
  setCurrentValue: React.Dispatch<React.SetStateAction<string>>
  options: string[]
}) => {
  const [isSelected, setIsSelected] = useState(false)
  const [isShowOptions, setShowOptions] = useState(false)

  const handleOnChangeSelectValue = (e) => {
    const { innerText } = e.target
    setIsSelected(true)
    setCurrentValue(innerText)
  }

  return (
    <SelectBox
      style={{ marginLeft: left }}
      $show={isShowOptions}
      onClick={() => setShowOptions((prev) => !prev)}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <Label
          style={{
            flex: 1,
            textAlign: 'left',
            color: isSelected ? '#333333' : '#949494'
          }}
        >
          {currentValue}
        </Label>
        <img
          width={'20px'}
          height={'20px'}
          src={
            isShowOptions
              ? require('../assets/img/up-arrow.png')
              : require('../assets/img/down-arrow.png')
          }
          alt='BigCo Inc. logo'
          style={{ marginRight: '20px' }}
        />
      </div>
      <SelectOptions $show={isShowOptions} $height={'270px'}>
        {options.map((option, index) => (
          <Option key={index} onClick={handleOnChangeSelectValue}>
            {option}
          </Option>
        ))}
      </SelectOptions>
    </SelectBox>
  )
}

const CustomTime = ({
  left,
  currentValue,
  setCurrentValue
}: {
  left: string
  currentValue: string
  setCurrentValue: React.Dispatch<React.SetStateAction<string>>
}) => {
  const [isSelected, setIsSelected] = useState(false)
  const [isShowOptions, setShowOptions] = useState(false)

  const handleOnChangeSelectValue = (e) => {
    const { innerText } = e.target
    setIsSelected(true)
    setCurrentValue(innerText)
  }

  return (
    <SelectBox
      style={{ marginLeft: left }}
      $show={isShowOptions}
      onClick={() => setShowOptions((prev) => !prev)}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <Label
          style={{
            flex: 1,
            textAlign: 'left',
            color: isSelected ? '#333333' : '#949494'
          }}
        >
          {currentValue}
        </Label>
        <img
          width={'20px'}
          height={'20px'}
          src={
            isShowOptions
              ? require('../assets/img/up-arrow.png')
              : require('../assets/img/down-arrow.png')
          }
          alt='BigCo Inc. logo'
          style={{ marginRight: '20px' }}
        />
      </div>
      <SelectOptions
        $show={isShowOptions}
        $height={'648px'}
        style={{ zIndex: 100 }}
      >
        {Array.from({ length: 12 }, (_, i) => i + 9).map((hour) => (
          <Option key={hour} onClick={handleOnChangeSelectValue}>
            {`${hour.toString().padStart(2, '0')} 시`}
          </Option>
        ))}
      </SelectOptions>
    </SelectBox>
  )
}

const ReturnRegist = () => {
  const navigate = useNavigate()
  const [value, onChange] = useState(new Date())
  const [time, setTime] = useState('시간')
  const [regType, setRegType] = useState(0)
  const [carType, setCarType] = useState('')
  const [carNumber, setCarNumber] = useState('')
  const [addr, setAddr] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [dispatch, setDispatch] = useState('배차 선택')
  const [etc, setEtc] = useState('')
  const [validToken, setValidToken] = useState(false)
  const token = useSelector((state: RootReducer) => state.Auth.token)
  const infos = JSON.parse(token)
  const [ticketCount, setTicketCount] = useState(0)
  const [show, setShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [file, setFile] = useState<File | null>(null)

  useEffect(() => {
    if (!IS_VALID_TOKEN(infos)) navigate('/login')
    else {
      setValidToken(true)

      const fetchUserInfo = async () => {
        try {
          const response = await axios.post(
            `${SERVER_URL()}/user/info`,
            { id: infos.data.idx },
            {
              headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${infos.data.auth_token}`
              }
            }
          )
          if (response.data.code === 0) {
            setTicketCount(response.data.data.ticket_count)
          }
        } catch (error) {
          console.error('Error fetching user info:', error)
          setError('Failed to fetch user information')
        }
      }

      fetchUserInfo()
    }
  }, [])

  const confirmClicked = () => {
    setShow(false)
    navigate('/list')
  }

  function cloneFormData(originalFormData) {
    const clonedFormData = new FormData();

    // Loop through the entries of the original FormData
    for (const [key, value] of originalFormData.entries()) {
      clonedFormData.append(key, value);
    }

    return clonedFormData;
  }

  const registResult = async (result: boolean) => {
    if (result) {
      if (ticketCount <= 0) {
        setShow(true)
      } else {
        try {
          setIsLoading(true)
          const formData = new FormData()

          formData.append('companyId', infos.data.idx.toString())
          formData.append('type', getTypeFromRegType(regType))
          if (time !== '시간') {
            formData.append(
              'requestDate',
              `${value.toISOString().split('T')[0]}`
            )
          } else {
            formData.append(
              'requestDate',
              `${value.toISOString().split('T')[0]} ${time}:00`
            )
          }
          formData.append('carInfo', `${carType} ${carNumber}`)
          formData.append('carLocation', addr)
          formData.append('renterName', name)
          formData.append('renterPhone', phone)
          formData.append('dispatchType', dispatch)
          formData.append('requestContent', etc)
          formData.append('partnerCompanyName', infos.data.company_name)

          if (file) {
            formData.append('image', file)
          }

          const formServer = cloneFormData(formData);
          formServer.append('carType', carType);
          formServer.append('carNumber', carNumber);
          // calling server
          await axios.post(
              `${API_URL()}/api/ticket/new`,
              formServer,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
          )


          const response = await axios.post(
            `${NEW_SERVER_URL()}/request-agency`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${infos.data.auth_token}`
              }
            }
          )
          if (response.data) {
            navigate('/list')
          }
        } catch (error) {
          console.error('Error creating request:', error)
          setError('Failed to submit request')
        } finally {
          setIsLoading(false)
        }
      }
    }
  }

  const getTypeFromRegType = (type: number): string => {
    switch (type) {
      case 0:
        return '경정비'
      case 1:
        return '사고수리'
      case 2:
        return '정기점검'
      case 3:
        return '렌터카-반납'
      default:
        return '경정비'
    }
  }

  const saveAddr = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddr(event.target.value)
  }

  const saveName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const savePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value)
  }

  const saveEtc = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEtc(event.target.value)
  }

  const getServiceInfoLabel = () => {
    switch (regType) {
      case 0:
        return '서비스 정보'
      case 1:
        return '서비스 정보'
      case 2:
        return '서비스 정보'
      case 3:
        return '서비스 정보'
      default:
        return '서비스 정보'
    }
  }

  const getDateTimeLabel = () => {
    switch (regType) {
      case 0:
        return '서비스 신청 일시'
      case 1:
        return '서비스 신청 일시'
      case 2:
        return '자동차 검사 유효기간'
      case 3:
        return '반납 신청 일시'
      default:
        return '서비스 신청 일시'
    }
  }

  const getCarInfoLabel = () => {
    switch (regType) {
      case 0:
      case 1:
      case 2:
      case 3:
        return '서비스 차량 정보'
      default:
        return '서비스 차량 정보'
    }
  }

  const getLocationLabel = () => {
    switch (regType) {
      case 0:
        return '서비스 차량 위치'
      case 1:
        return '서비스 차량 위치 (사고 현장 위치)'
      case 2:
        return '서비스 차량 위치'
      case 3:
        return '서비스 차량 위치'
      default:
        return '서비스 차량 위치'
    }
  }

  const getRentalInfoLabel = () => '임대차 계약 정보'

  const getDispatchLabel = () => {
    switch (regType) {
      case 0:
        return '배차 형태'
      case 1:
        return '사고 유형'
      case 2:
        return '자동차 검사 유형'
      case 3:
        return '배차 형태'
      default:
        return '배차 형태'
    }
  }

  const getDispatchOptions = () => {
    switch (regType) {
      case 0:
      case 3:
        return ['일반 단기', '일반 장기', '기타']
      case 1:
        return ['가해', '피해', '과실', '단독', '기타(원인불명 등)']
      case 2:
        return ['정기', '종합', '연장', '차령']
      default:
        return ['일반 단기', '일반 장기', '기타']
    }
  }

  const getRequestPlaceholder = () => {
    switch (regType) {
      case 0:
        return '엔진 오일 교환 필요 등'
      case 1:
        return '서비스 상세 요청 사항 (예: 제휴 사고수리 공장으로 입고 후 견적 확인 및 차량 보관)'
      case 2:
        return '서비스 상세 요청 사항 (예: 자동차 검사 유효기간 만료 임박 등)'
      case 3:
        return '서비스 상세 요청 사항 (예: 전체 점검 및 경정비 필요, 실내 흡연 여/부 파악 요청)'
      default:
        return '서비스 상세 요청 사항'
    }
  }

  const getImageUploader = () => {
    switch (regType) {
      case 0:
        return (
          <div
            style={{
              margin: 0,
              display: 'flex',
              flexDirection: 'column',
              marginTop: '10px',
              marginBottom: '32px'
            }}
          >
            <Text
              $size={'14px'}
              $weight={400}
              $color={'#949494'}
              style={{ margin: 0 }}
            >
              정비 요청 사진 업로드
            </Text>
            <input
              type='file'
              multiple
              onChange={(e) => {
                if (e.target.files !== null) {
                  setFile(e.target.files[0])
                }
              }}
            />
          </div>
        )
      case 1:
        return (
          <div
            style={{
              margin: 0,
              display: 'flex',
              flexDirection: 'column',
              marginTop: '10px',
              marginBottom: '32px'
            }}
          >
            <Text
              $size={'14px'}
              $weight={400}
              $color={'#949494'}
              style={{ margin: 0 }}
            >
              차량 파손 사진 업로드
            </Text>
            <input
              type='file'
              multiple
              onChange={(e) => {
                if (e.target.files !== null) {
                  setFile(e.target.files[0])
                }
              }}
            />
          </div>
        )
      default:
        return <></>
    }
  }

  return (
    <>
      <LayoutLong height={1810}>
        <div
          style={{
            maxWidth: '1320px',
            height: '1690px',
            position: 'absolute',
            borderRadius: '25px',
            left: '0',
            top: '140px',
            right: '0',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              width: '420px',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flow'
            }}
          >
            <div
              style={{
                width: '420px',
                height: '240px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                borderRadius: '25px',
                left: '0',
                top: '0px',
                bottom: '0px',
                right: '0',
                margin: 'auto',
                background: 'white',
                boxShadow: '0px 2px 18px 0px rgba(0,0,0,0.3)'
              }}
            >
              <div
                style={{
                  width: '420px',
                  flex: 1,
                  marginTop: '56px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <div
                  style={{
                    width: '360px',
                    height: '100%',
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <img
                    width={'48px'}
                    height={'48px'}
                    src={require('../assets/img/car-icon.png')}
                    alt='BigCo Inc. logo'
                    style={{ marginRight: '10px' }}
                  />

                  <div
                    style={{
                      height: '100%',
                      flex: 1,
                      flexDirection: 'column',
                      justifyContent: 'center',
                      display: 'flex'
                    }}
                  >
                    <Text $size={'20px'} $weight={500} style={{ margin: '0' }}>
                      {validToken ? infos.data.user_name : ''}
                    </Text>
                    <Text $size={'16px'} $weight={400} style={{ margin: '0' }}>
                      {validToken ? infos.data.company_name : ''}
                    </Text>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: '360px',
                  height: '1px',
                  marginTop: '31px',
                  background: '#E5E5E5'
                }}
              ></div>
              <div
                style={{
                  width: '360px',
                  flex: 1,
                  marginTop: '16px',
                  marginBottom: '0px',
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <Text
                  $size={'20px'}
                  $weight={500}
                  style={{ margin: '0', flex: 1 }}
                >
                  잔여 이용권
                </Text>
                <Text
                  $size={'20px'}
                  $weight={400}
                  style={{ margin: '0', flex: 1, textAlign: 'right' }}
                >
                  {validToken ? ticketCount : '0'} 개
                </Text>
              </div>
            </div>
          </div>
          {/* 중간 구분 Space */}
          <div style={{ width: '30px' }}></div>
          {/* 중간 구분 Space */}
          <div
            style={{
              flex: 1,
              width: '100%',
              height: '100%',
              background: 'white',
              boxShadow: '0px 2px 18px 0px rgba(0,0,0,0.3)',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '25px',
              alignItems: 'center'
            }}
          >
            <div
              style={{
                width: '770px',
                marginTop: '60px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Text $size={'24px'} $weight={800} style={{ margin: 0, flex: 1 }}>
                신청서 작성
              </Text>

              <img
                width={'36px'}
                height={'36px'}
                src={require('../assets/img/close.png')}
                alt='BigCo Inc. logo'
                onClick={() => navigate('/member')}
                style={{ cursor: 'pointer' }}
              />
            </div>

            <DivLine top={'24px'} bottom={'30px'} />
            <RowDiv
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <Text $size={'20px'} $weight={700} style={{ margin: 0 }}>
                상품 선택
              </Text>
              <Text
                $size={'14px'}
                $weight={400}
                $color={'#0862A9'}
                style={{ margin: 0, marginLeft: '4px' }}
              >
                (*필수)
              </Text>
            </RowDiv>

            <RowDiv
              style={{
                cursor: 'pointer',
                flexDirection: 'row',
                display: 'flex',
                marginTop: '20px'
              }}
            >
              <div
                onClick={() => setRegType(0)}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <img
                  width={'20px'}
                  height={'20px'}
                  src={
                    regType == 0
                      ? require('../assets/img/on.png')
                      : require('../assets/img/off.png')
                  }
                  alt='BigCo Inc. logo'
                />
                <Text
                  $size={'18px'}
                  $weight={400}
                  style={{ margin: 0, marginLeft: '8px' }}
                >
                  경정비 서비스
                </Text>
              </div>

              <div
                onClick={() => setRegType(1)}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginLeft: '40px'
                }}
              >
                <img
                  width={'20px'}
                  height={'20px'}
                  src={
                    regType == 1
                      ? require('../assets/img/on.png')
                      : require('../assets/img/off.png')
                  }
                  alt='BigCo Inc. logo'
                />
                <Text
                  $size={'18px'}
                  $weight={400}
                  style={{ margin: 0, marginLeft: '8px' }}
                >
                  사고수리 서비스
                </Text>
              </div>

              <div
                onClick={() => setRegType(2)}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginLeft: '40px'
                }}
              >
                <img
                  width={'20px'}
                  height={'20px'}
                  src={
                    regType == 2
                      ? require('../assets/img/on.png')
                      : require('../assets/img/off.png')
                  }
                  alt='BigCo Inc. logo'
                />
                <Text
                  $size={'18px'}
                  $weight={400}
                  style={{ margin: 0, marginLeft: '8px' }}
                >
                  자동차 검사 서비스
                </Text>
              </div>

              <div
                onClick={() => setRegType(3)}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginLeft: '40px'
                }}
              >
                <img
                  width={'20px'}
                  height={'20px'}
                  src={
                    regType == 3
                      ? require('../assets/img/on.png')
                      : require('../assets/img/off.png')
                  }
                  alt='BigCo Inc. logo'
                />
                <Text
                  $size={'18px'}
                  $weight={400}
                  style={{ margin: 0, marginLeft: '8px' }}
                >
                  렌터카 반납 서비스
                </Text>
              </div>
            </RowDiv>

            <DivLine top={'30px'} bottom={'30px'} />

            <RowDiv>
              <Text $size={'20px'} $weight={700} style={{ margin: 0 }}>
                {getServiceInfoLabel()}
              </Text>
            </RowDiv>
            <RowDiv
              style={{
                marginTop: '32px',
                flexDirection: 'column',
                display: 'flex'
              }}
            >
              <div style={{ margin: 0, display: 'flex' }}>
                <Text $size={'18px'} $weight={400} style={{ margin: 0 }}>
                  {getDateTimeLabel()}
                </Text>
                <Text
                  $size={'14px'}
                  $weight={400}
                  $color={'#0862A9'}
                  style={{
                    margin: 0,
                    marginLeft: '4px',
                    display: regType !== 0 && regType !== 1 ? 'block' : 'none'
                  }}
                >
                  (*필수)
                </Text>
              </div>

              <div
                style={{
                  margin: 0,
                  display: 'flex',
                  marginTop: '8px'
                }}
              >
                <CustomCalendar
                  value={value}
                  onChange={onChange}
                  serviceType={
                    regType === 0 ? '경정비' : regType === 1 && '사고수리'
                  }
                />
                {regType === 3 && (
                  <CustomTime
                    left={'10px'}
                    currentValue={time}
                    setCurrentValue={setTime}
                  />
                )}
              </div>
            </RowDiv>

            <RowDiv
              style={{
                flexDirection: 'column',
                display: 'flex'
              }}
            >
              <div style={{ margin: 0, display: 'flex', marginTop: '32px' }}>
                <Text $size={'18px'} $weight={400} style={{ margin: 0 }}>
                  {getCarInfoLabel()}
                </Text>
                <Text
                  $size={'14px'}
                  $weight={400}
                  $color={'#0862A9'}
                  style={{ margin: 0, marginLeft: '4px' }}
                >
                  (*필수)
                </Text>
              </div>

              <div style={{ margin: 0, display: 'flex', marginBottom: '8px' }}>
                <Text
                  $size={'14px'}
                  $weight={400}
                  $color={'#949494'}
                  style={{ margin: 0 }}
                >
                  차종, 차량 번호
                </Text>
                <Text
                  $size={'14px'}
                  $weight={400}
                  $color={'#949494'}
                  style={{ margin: 0 }}
                >
                  (ex. 투싼, 11가 2222)
                </Text>
              </div>

              <CarInfo
                carType={carType}
                setCarType={setCarType}
                carNumber={carNumber}
                setCarNumber={setCarNumber}
              />
            </RowDiv>

            <RowDiv
              style={{
                height: '90px',
                marginTop: '32px'
              }}
            >
              <div style={{ margin: 0, display: 'flex' }}>
                <Text $size={'18px'} $weight={400} style={{ margin: 0 }}>
                  {getLocationLabel()}
                </Text>
              </div>

              <div style={{ margin: 0, display: 'flex' }}>
                <Text
                  $size={'14px'}
                  $weight={400}
                  $color={'#949494'}
                  style={{ margin: 0 }}
                >
                  읍, 면, 동까지 입력
                </Text>
                <Text
                  $size={'14px'}
                  $weight={400}
                  $color={'#949494'}
                  style={{ margin: 0 }}
                >
                  (ex. 서울시 강남구 청담동)
                </Text>
              </div>

              <div style={{ margin: 0, display: 'flex', marginTop: '8px' }}>
                <BorderInput
                  placeholder={'읍, 면, 동까지 입력'}
                  value={addr}
                  onChange={saveAddr}
                  style={{}}
                ></BorderInput>
              </div>
            </RowDiv>

            <DivLine top={'60px'} bottom={'40px'} />

            <RowDiv style={{ marginBottom: '32px' }}>
              <Text $size={'20px'} $weight={700} style={{ margin: 0 }}>
                {getRentalInfoLabel()}
              </Text>
            </RowDiv>

            <RowDiv style={{}}>
              <div style={{ margin: 0, display: 'flex' }}>
                <Text $size={'18px'} $weight={400} style={{ margin: 0 }}>
                  임차인 정보
                </Text>
                <Text
                  $size={'14px'}
                  $weight={400}
                  $color={'#0862A9'}
                  style={{ margin: 0, marginLeft: '4px' }}
                >
                  (*필수)
                </Text>
              </div>

              <div
                style={{
                  margin: 0,
                  display: 'flex',
                  marginTop: '10px',
                  marginBottom: '32px'
                }}
              >
                <BorderInput
                  placeholder={'이름'}
                  value={name}
                  onChange={saveName}
                  style={{}}
                ></BorderInput>
              </div>
            </RowDiv>

            <RowDiv style={{}}>
              <div style={{ margin: 0, display: 'flex' }}>
                <Text $size={'18px'} $weight={400} style={{ margin: 0 }}>
                  임차인 연락처
                </Text>
                <Text
                  $size={'14px'}
                  $weight={400}
                  $color={'#0862A9'}
                  style={{ margin: 0, marginLeft: '4px' }}
                >
                  (*필수)
                </Text>
              </div>

              <div
                style={{
                  margin: 0,
                  display: 'flex',
                  marginTop: '10px',
                  marginBottom: '32px'
                }}
              >
                <BorderInput
                  placeholder={'010-0000-0000'}
                  value={phone}
                  onChange={savePhone}
                  style={{}}
                ></BorderInput>
              </div>
            </RowDiv>

            <RowDiv style={{}}>
              <div style={{ margin: 0, display: 'flex' }}>
                <Text $size={'18px'} $weight={400} style={{ margin: 0 }}>
                  {getDispatchLabel()}
                </Text>
                {(regType === 1 || regType === 2) && (
                  <Text
                    $size={'14px'}
                    $weight={400}
                    $color={'#0862A9'}
                    style={{ margin: 0, marginLeft: '4px' }}
                  >
                    (*필수)
                  </Text>
                )}
              </div>

              <div
                style={{
                  margin: 0,
                  display: 'flex',
                  marginTop: '10px'
                }}
              >
                <CustomSelect
                  left={'0px'}
                  currentValue={dispatch}
                  setCurrentValue={setDispatch}
                  options={getDispatchOptions()}
                />
              </div>
            </RowDiv>

            <DivLine top={'30px'} bottom={'30px'} />

            <RowDiv style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ margin: 0, display: 'flex' }}>
                <Text $size={'18px'} $weight={400} style={{ margin: 0 }}>
                  요청 사항
                </Text>
              </div>

              <div
                style={{
                  margin: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '10px'
                }}
              >
                <TextArea
                  rows={5}
                  cols={35}
                  placeholder={getRequestPlaceholder()}
                  value={etc}
                  onChange={saveEtc}
                />
                {getImageUploader()}
              </div>
            </RowDiv>

            <RowDiv
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                marginTop: '64px',
                marginBottom: '60px'
              }}
            >
              <button
                onClick={() => registResult(true)}
                disabled={isLoading}
                style={{
                  border: 'none',
                  background: '#22B4E2',
                  color: 'white',
                  textAlign: 'center',
                  textDecoration: 'none',
                  display: 'inline-block',
                  margin: '4px, 2px',
                  width: '418px',
                  height: '60px',
                  borderRadius: '70px',
                  fontFamily: 'Pretendard',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '24px',
                  cursor: 'pointer'
                }}
              >
                {isLoading ? '처리 중...' : '신청하기'}
              </button>
            </RowDiv>
            {error && (
              <Text $size={'14px'} $weight={400} $color={'red'}>
                {error}
              </Text>
            )}
          </div>
        </div>
      </LayoutLong>

      {show && <ConfirmPopUp errMsg={buyMsg} clickEvent={confirmClicked} />}
    </>
  )
}

export default ReturnRegist
