import React from 'react'
import styled from 'styled-components'

const BorderInput = styled.input`
  margin: 0;
  width: 240px;
  height: 54px;
  background: #fdfdfd;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  &:focus {
    border-color: #22b4e2;
    transition: border-color 0.3s ease-in-out;
    outline: 0;
  }
  color: #333333;
  padding-right: 10px;
  padding-left: 10px;
  font-family: 'Pretendard';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
`

const CarInfo = ({
  carType,
  setCarType,
  carNumber,
  setCarNumber
}: {
  carType: string
  setCarType: React.Dispatch<React.SetStateAction<string>>
  carNumber: string
  setCarNumber: React.Dispatch<React.SetStateAction<string>>
}) => {
  //차종 입력
  const saveCarType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCarType(event.target.value)
  }

  //차량 번호 입력
  const saveCarNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCarNumber(event.target.value)
  }

  return (
    <div style={{ margin: 0, display: 'flex' }}>
      <BorderInput
        placeholder={'차종'}
        value={carType}
        onChange={saveCarType}
        style={{}}
      ></BorderInput>
      <BorderInput
        placeholder={'차량 번호'}
        value={carNumber}
        onChange={saveCarNumber}
        style={{ marginLeft: '10px' }}
      ></BorderInput>
    </div>
  )
}

export default CarInfo
