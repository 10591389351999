import React from 'react'
import footerlogo from '../img/footer_logo.png'
import styled from 'styled-components'

const FooterList = styled.div`
  height: 200px;
  background: #333;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
`

// const FooterBG = styled.div`
//   width: 1920px;
//   height: 200px;
// `
// const FooterImg = () => {
//   return (
//     <FooterBG>
//       <img
//         style={{ margin: 'auto', display: 'block' }}
//         width={'1920px'}
//         height={'200px'}
//         src={require('../assets/img/footer.png')}
//         alt='BigCo Inc. logo'
//       />
//     </FooterBG>
//   )
// }
const Footer = () => {
  return (
    <FooterList>
      <footer>
        <div className='inner'>
          <img src={footerlogo} alt='carbell' className='footer_logo' />
          <div className='text'>
            <div className='copy'>
              ©2023. Carbell Inc. All Rights Reserved.
            </div>
            <address>
              <span>카벨</span>
              <span>|</span>
              <span>사업자등록번호 702-87-03104 </span>
              <span>대표자명 서윤식</span>
              <br />
              <span>고객센터번호: 1544-1428</span>
              <span>|</span>
              <span>E-mail: cs@carbell.co.kr</span>
            </address>
          </div>
        </div>
      </footer>
    </FooterList>
  )
}

export default Footer
