import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LayoutM from '../../components/LayoutM'
import { useSelector } from 'react-redux'
import { RootReducer } from '../../redux/reducers/RootReducer'
import { useNavigate } from 'react-router-dom'
import {
  IS_VALID_TOKEN,
  sendBuySlackMessage,
  SERVER_URL
} from '../../components/Common'
import { buyMsg, YesNoPopUpM } from '../../components/popup/PopUp'

const Button = styled.button<{
  $width?: string
  $height?: string
  $color?: string
  $flex?: number
}>`
  width: ${(props) => (props.$width ? props.$width : '0px')};
  height: ${(props) => (props.$height ? props.$height : '0px')};
  font-family: 'Pretendard';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  background: ${(props) => (props.$color ? props.$color : 'white')};
  color: white;
  border: none;
  border-radius: 10px;
  flex: ${(props) => (props.$flex ? props.$flex : 'none')};
`

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #e5e5e5;
`
const DivLine = ({ top, bottom }: { top: string; bottom: string }) => {
  return (
    <Line style={{ marginTop: top, marginBottom: bottom }}>
      <p />
    </Line>
  )
}

const Text = styled.p<{ $size?: string; $weight?: number }>`
  font-family: 'Pretendard';
  font-size: ${(props) => (props.$size ? props.$size : '20px')};
  font-style: normal;
  font-weight: ${(props) => (props.$weight ? props.$weight : 400)};
  line-height: 26px;
  color: #191919;
`
const MemberM = () => {
  const navigate = useNavigate()
  const [validToken, setValidToken] = useState(false)
  const [ticketCount, setTicketCount] = useState(0)

  const token = useSelector((state: RootReducer) => state.Auth.token)
  const infos = JSON.parse(token)
  useEffect(() => {
    const infos = JSON.parse(token)
    if (!IS_VALID_TOKEN(infos)) navigate('/login')
    else {
      setValidToken(true)

      const params = {
        // 필요한 query params를 {} 형태에 담아준다.
        id: infos.data.idx
      }

      fetch(`${SERVER_URL()}/user/info`, {
        method: 'POST', //메소드 지정
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${infos.data.auth_token}`
        },
        body: JSON.stringify(params)
      })
        .then((res) => res.json()) // 리턴값이 있으면 리턴값에 맞는 req 지정
        .then((res) => {
          if (res.code == 0) {
            setTicketCount(res.data.ticket_count)
          }
        })
    }
  }, [])

  const [show, setShow] = useState(false)
  const [errMsg] = useState(buyMsg)

  const clickEvent = (value: boolean) => {
    setShow(false)
    if (value) sendBuySlackMessage(infos.data.company_name, infos.data.user_tel)
  }

  return (
    <>
      <LayoutM myPage={false}>
        <div
          style={{
            width: '100%',
            height: '100%',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <DivLine bottom={'37px'} top={'0px'} />
          <div
            style={{
              width: 'calc(100vw - 44px)',
              height: '100%'
            }}
          >
            <div
              style={{
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'center',
                display: 'flex'
              }}
            >
              <img
                width={'48px'}
                height={'48px'}
                src={require('../../assets/img/car-icon.png')}
                alt='BigCo Inc. logo'
              />
              <div
                style={{
                  width: '100%',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  display: 'flex',
                  marginLeft: '10px'
                }}
              >
                <Text $size={'16px'} $weight={500} style={{ margin: '0' }}>
                  {validToken ? infos.data.user_name : ''}
                </Text>
                <Text $size={'12px'} $weight={400} style={{ margin: '0' }}>
                  {validToken ? infos.data.company_name : ''} ・{' '}
                  {validToken ? infos.data.user_email : ''}
                </Text>
              </div>
            </div>
            <DivLine bottom={'28px'} top={'37px'} />

            <div
              style={{
                width: '100%',
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flex: 1
              }}
            >
              <Text $size={'14px'} $weight={500} style={{ flex: 1 }}>
                잔여 이용권
              </Text>
              <Text
                $size={'14px'}
                $weight={400}
                style={{ flex: 1, textAlign: 'right' }}
              >
                {validToken ? ticketCount : '0'} 개
              </Text>
            </div>
            <Button
              onClick={() => setShow(true)}
              $width={'100%'}
              $height={'48px'}
              $color={'#22B4E2'}
              style={{ marginTop: '20px' }}
            >
              이용권 구매
            </Button>

            <DivLine bottom={'20px'} top={'28px'} />

            <Button
              $width={'100%'}
              $height={'48px'}
              $color={'#0862A9'}
              onClick={() => navigate('/regist')}
            >
              신청하기
            </Button>

            <Button
              $width={'100%'}
              $height={'48px'}
              $color={'#0862A9'}
              style={{ marginTop: '10px' }}
              onClick={() => navigate('/list')}
            >
              신청 이력
            </Button>
          </div>
        </div>
      </LayoutM>
      <> {show && <YesNoPopUpM errMsg={errMsg} clickEvent={clickEvent} />}</>
    </>
  )
}

export default MemberM
