const SET_TOKEN = 'set_token'
const INIT_TOKEN = 'init_token'

const AuthInitialState = {
  token: null
}

export const setToken = (token) => ({
  type: SET_TOKEN,
  token
})

export const initToken = () => ({
  type: INIT_TOKEN
})

export const AuthReducer = (state = AuthInitialState, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.token
      }
    case INIT_TOKEN:
      return {
        token: AuthInitialState.token
      }
    default:
      return state
  }
}
