import React, { useEffect } from 'react'
import styled from 'styled-components'
import LayoutM from '../../components/LayoutM'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootReducer } from '../../redux/reducers/RootReducer'
import { IS_VALID_TOKEN } from '../../components/Common'
import ReqDataM from '../../components/ReqDataM'

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #e5e5e5;
`

const DivLine = ({ top, bottom }: { top: string; bottom: string }) => {
  return (
    <Line style={{ marginTop: top, marginBottom: bottom }}>
      <p />
    </Line>
  )
}

const Text = styled.p<{ $size?: string; $weight?: number; $color?: string }>`
  font-family: 'Pretendard';
  font-size: ${(props) => (props.$size ? props.$size : '20px')};
  font-style: normal;
  font-weight: ${(props) => (props.$weight ? props.$weight : 400)};
  line-height: 26px;
  color: ${(props) => (props.$color ? props.$color : '#191919')};
`

const ReturnListM = () => {
  const navigate = useNavigate()
  //const [datas, setDatas] = useState([])

  const token = useSelector((state: RootReducer) => state.Auth.token)
  const infos = JSON.parse(token)

  useEffect(() => {
    const infos = JSON.parse(token)
    if (!IS_VALID_TOKEN(infos)) navigate('/login')
  }, [])

  return (
    <LayoutM myPage={true}>
      <div
        style={{
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <DivLine bottom={'21px'} top={'0px'} />
        <div
          style={{
            width: 'calc(100vw - 44px)',
            height: '100%'
          }}
        >
          <div
            style={{
              width: '100%',
              flexDirection: 'column',
              justifyContent: 'center',
              display: 'flex'
            }}
          >
            <Text $size={'16px'} $weight={400} style={{ margin: '0' }}>
              신청 이력
            </Text>
            <DivLine bottom={'20px'} top={'19px'} />

            <div style={{ background: 'transparent' }}>
              <ReqDataM
                cmpIdx={infos != null ? infos.data.idx : '0'}
                token={infos != null ? infos.data.auth_token : ''}
                navigate={navigate}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutM>
  )
}

export default ReturnListM
