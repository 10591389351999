import React from 'react'
import Login from './pages/Login'
import Member from './pages/Member'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ReturnRegist from './pages/ReturnRegist'
import ReturnList from './pages/ReturnList'
import './font/pretendard.css'
import { isMobile } from 'react-device-detect'
import LoginM from './pages/mobile/LoginM'
import MemberM from './pages/mobile/MemberM'
import ReturnRegistM from './pages/mobile/ReturnRegistM'
import ReturnListM from './pages/mobile/ReturnListM'
import { Provider } from 'react-redux'
import store from './redux/configStore'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
const persistor = persistStore(store)
import '../src/css/App.css'
import Home from './pages/Home'

//스토어 생성하기

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          {isMobile ? (
            <Routes>
              {/* 웹 서비스 소개 페이지 */}
              <Route path='/' element={<Home />} />
              <Route path='/login' element={<LoginM />} />
              {/* <SignIn /> */}
              <Route path='/member' element={<MemberM />} />
              <Route path='/regist' element={<ReturnRegistM />} />
              <Route path='/list' element={<ReturnListM />} />
            </Routes>
          ) : (
            <Routes>
              {/*웹 서비스 소개 페이지*/}
              <Route path='/' element={<Home />} />
              <Route path='/login' element={<Login />} />
              {/* <SignIn /> */}
              <Route path='/member' element={<Member />} />
              <Route path='/regist' element={<ReturnRegist />} />
              <Route path='/list' element={<ReturnList />} />
            </Routes>
          )}
        </BrowserRouter>
      </PersistGate>
    </Provider>
  )
}

export default App
