import styled from 'styled-components'

function Pagination({ total, limit, page, onChanged }) {
  const numPages = Math.ceil(total / 6)
  const startIndex = (Math.ceil(page / limit) - 1) * limit
  return (
    <Nav>
      <Button disabled={page === 1}>&lt;&lt;</Button>
      <Button onClick={() => onChanged(page - 1)} disabled={page === 1}>
        &lt;
      </Button>
      {Array.from({ length: numPages }, (_, i) => i + 1).map((_, i) => (
        <Button
          key={i + 1}
          onClick={() => onChanged(i + 1)}
          aria-current={page === startIndex + i + 1 ? 'page' : undefined}
        >
          {startIndex + i + 1}
        </Button>
      ))}
      <Button onClick={() => onChanged(page + 1)} disabled={page === numPages}>
        &gt;
      </Button>
      <Button disabled={page === numPages}>&gt;&gt;</Button>
    </Nav>
  )
}

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin: 16px;
  background-color: transparent;
`

const Button = styled.button`
  border: none;
  border-radius: 5px;
  width: 28px;
  height: 28px;
  margin: 0;
  background: transparent;
  color: #949494;
  font-family: 'Pretendard';
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;

  //&:hover {
  //  background: #0862a9;
  //  cursor: pointer;
  //  transform: translateY(-2px);
  //}

  &[disabled] {
    color: #e5e5e5;
    background: transparent;
    cursor: revert;
    transform: revert;
  }

  &[aria-current] {
    background: #0862a9;
    font-weight: bold;
    cursor: revert;
    transform: revert;
    color: white;
  }
`

export default Pagination
